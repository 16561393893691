import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faChevronUp, faCogs, faCommentDollar, faDollarSign, faEnvelope, faHome, faIdCard, faInfoCircle, faMapMarkerAlt, faPhone, faPhoneAlt, faQuestionCircle, 
         faServer, faSortNumericUpAlt, faTimes, faUser,faUserCircle, faRedo, faRegistered, faDesktop, 
         faSpinner,
         faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import * as Scroll from 'react-scroll'

import DatosSPG from '../componentes/funciones';
import Fecha from '../componentes/fecha';
import Permisos from '../objetos/permisos';
import ChartLines from '../objetos/ChartLines';

const version = require('../version.json');

class Pie extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = { 
                        permisos: [],
                        Noticias: [],
                        server: [],
                        statsServer1: [],
                        statsServer2: [],
                        pagar: false,
                        ayuda: false,
                        switch: false,
                        verEstadoServer: false,
                        stats: false, 
                        seccion: 1, 
                        intervalo: 0,
                        periodo: "30M",
                        esperando: false
                     }
    }

    render()
    { 
        const urlElements = window.location.href.split('/');
        const aServer = this.state.server
    
        let bIndex = false
        
        if(urlElements[3].toLocaleLowerCase() === "" && urlElements.length === 4)
        {
            bIndex = true 
        }

        let fecha = new Date()
        let cPantalla = urlElements[3].toLowerCase()
        let cVisible = ""
        let oPestanaServer = []
        
        if (cPantalla === "reportes")
        {
            cVisible = "invisible"
        }

        if (bIndex)
        { 
            let oListaStats = []

            //const aStatsServer1 = this.state.statsServer1
            const aStatsServer2 = this.state.statsServer2
            
            if (!aServer.server)
            {
                aServer.server = "bd-01-softplace-software"
            }
            
            if (this.state.stats && this.state.seccion === 2)
            {
                //let aCPUBussy = []
                let aCPUBussyTitulo = []
                let aCPUBussyLabels = []

                //let aConnections = []
                let aConnectionsTitulo = []
                let aConnectionsLabels = []
                
                //let aIOBussy = []
                let aIOBussyTitulo = []
                let aIOBussyLabels = []
                //let aLect = []
                let aLectTitulo = []
                let aLectLabels = []
                //let aEscrit = []
                let aEscritTitulo = []
                let aEscritLabels = []
                //let aPKRecib = []
                let aPKRecibTitulo = []
                let aPKRecibLabels = []
                //let aPKEnviados = []
                let aPKEnviadosTitulo = []
                let aPKEnviadosLabels = []
                let aCPUBussy2 = []
                let aCPUBussyTitulo2 = []
                
                let aConnectionsTitulo2 = []
                let aConnections2 = []

                let aIOBussy2 = []
                let aIOBussyTitulo2 = []
                let aLect2 = []
                let aLectTitulo2 = []
                let aEscrit2 = []
                let aEscritTitulo2 = []
                let aPKRecib2 = []
                let aPKRecibTitulo2 = []
                let aPKEnviados2 = []
                let aPKEnviadosTitulo2 = []
                
                if (aStatsServer2.length)
                {
                    //let nAntCPUBussy = aStatsServer1[0].CPUBussy
                    //let nAntIOBussy = aStatsServer1[0].IO_Bussy
                    //let nAntLecturas = aStatsServer1[0].Lecturas
                    //let nAntEscrituras = aStatsServer1[0].Escrituras
                    //let nAntPKRecib = aStatsServer1[0].PkRecibidos
                    //let nAntPKEnv = aStatsServer1[0].PkEnviados
                    let nAntCPUBussy2 = aStatsServer2[0].Bussy
                    let nAntConnections2 = aStatsServer2[0].Connections
                    let nAntIOBussy2 = aStatsServer2[0].IO_Bussy
                    let nAntLecturas2 = aStatsServer2[0].Lecturas
                    let nAntEscrituras2 = aStatsServer2[0].Escrituras
                    let nAntPKRecib2 = aStatsServer2[0].PkRecibidos
                    let nAntPKEnv2 = aStatsServer2[0].PkEnviados
                    
                    for (let x2 = 0; x2 < aStatsServer2.length; x2++)
                    { 
                        // solo pone la diferencia desde el primer item, ya que el numero significa la cantidad de segundos que ha estado desocupado el CPU desde el ultimo inicio y con regla de 3 simple, saca el porcentaje respectod e 1 minuto
                        //aCPUBussy.push(parseInt((x2 > 0 ? (aStatsServer1[x2].Bussy - nAntCPUBussy) : (aStatsServer1[x2 + 1].Bussy - aStatsServer1[x2].Bussy)) * 100 / 60))
                        if (aStatsServer2[x2].Bussy)
                        {
                            if (aStatsServer2[x2].Bussy - nAntCPUBussy2 < 0)
                            {
                                nAntCPUBussy2 = aStatsServer2[x2].Bussy
                            }

                            aCPUBussy2.push(parseInt(((aStatsServer2[x2].Bussy - nAntCPUBussy2) * 100 / 60) / (aServer.CPU ? aServer.CPU : 1)))
                        }
                        else 
                        {
                            aCPUBussy2.push(0)
                        }

                        aCPUBussyLabels.push(aStatsServer2[x2].fecha.substr(11, 5))
                        
                        //nAntCPUBussy = aStatsServer1[x2].Bussy 
                        nAntCPUBussy2 = (aStatsServer2[x2].Bussy ? aStatsServer2[x2].Bussy : nAntCPUBussy2) 

                        // Cantidad de conexiones, hace lo mismo que el cpu, segun los segundos ocupados, calcula el promedio x seg
                        if (aStatsServer2[x2].Connections)
                        {
                            if (aStatsServer2[x2].Connections - nAntConnections2 < 0)
                            {
                                nAntConnections2 = aStatsServer2[x2].Connections
                            }

                            aConnections2.push(parseInt((aStatsServer2[x2].Connections - nAntConnections2) / 60))
                        }
                        else 
                        {
                            aConnections2.push(0)
                        }

                        aConnectionsLabels.push(aStatsServer2[x2].fecha.substr(11, 5))
                        
                        //nAntConnections = aStatsServer1[x2].Connections
                        nAntConnections2 = (aStatsServer2[x2].Connections ? aStatsServer2[x2].Connections : nAntConnections2)

                        // I/O Ocuada, hace lo mismo que el cpu, segun los segundos ocupados, calcula el porcentaje, con regla de 3 simple
                        //aIOBussy.push(parseInt((aStatsServer1[x2].IO_Bussy - nAntIOBussy) * 100 / 60))
                        if (aStatsServer2[x2].IO_Bussy)
                        {
                            if (aStatsServer2[x2].IO_Bussy - nAntIOBussy2 < 0)
                            {
                                nAntIOBussy2 = aStatsServer2[x2].IO_Bussy
                            }

                            aIOBussy2.push(parseInt((aStatsServer2[x2].IO_Bussy - nAntIOBussy2) * 100 / 60))
                        }
                        else 
                        {
                            aIOBussy2.push(0)
                        }

                        aIOBussyLabels.push(aStatsServer2[x2].fecha.substr(11, 5))
                        
                        //nAntIOBussy = aStatsServer1[x2].IO_Bussy 
                        nAntIOBussy2 = (aStatsServer2[x2].IO_Bussy ? aStatsServer2[x2].IO_Bussy : nAntIOBussy2) 

                        // En el caso de las lecturas en disco, sólo pone la diferencia entre uno y otro bit 
                        //aLect.push(aStatsServer1[x2].Lecturas - nAntLecturas)
                        if (aStatsServer2[x2].Lecturas)
                        {
                            if (aStatsServer2[x2].Lecturas - nAntLecturas2 < 0)
                            {
                                nAntLecturas2 = aStatsServer2[x2].Lecturas
                            }

                            aLect2.push(aStatsServer2[x2].Lecturas - nAntLecturas2)
                        }
                        else 
                        {
                            aLect2.push(0)
                        }

                        aLectLabels.push(aStatsServer2[x2].fecha.substr(11, 5))
                        
                        //nAntLecturas = aStatsServer1[x2].Lecturas 
                        nAntLecturas2 = (aStatsServer2[x2].Lecturas ? aStatsServer2[x2].Lecturas : nAntLecturas2) 

                        // En el caso de las escrituras en disco, hace lo mismo que las lecturas, sólo pone la diferencia entre uno y otro bit 
                        //aEscrit.push(aStatsServer1[x2].Escrituras - nAntEscrituras)
                        if (aStatsServer2[x2].Escrituras)
                        {
                            if (aStatsServer2[x2].Escrituras - nAntEscrituras2 < 0)
                            {
                                nAntEscrituras2 = aStatsServer2[x2].Escrituras
                            }

                            aEscrit2.push(aStatsServer2[x2].Escrituras - nAntEscrituras2)
                        }
                        else 
                        {
                            aEscrit2.push(0)
                        }

                        aEscritLabels.push(aStatsServer2[x2].fecha.substr(11, 5))
                        
                        //nAntEscrituras = aStatsServer1[x2].Escrituras 
                        nAntEscrituras2 = (aStatsServer2[x2].Escrituras ? aStatsServer2[x2].Escrituras : nAntEscrituras2) 

                        // Paquetes recibidos, también saca la diferencia entre item e item 
                        //aPKRecib.push(aStatsServer1[x2].PkRecibidos - nAntPKRecib)
                        if (aStatsServer2[x2].PkRecibidos)
                        {
                            if (aStatsServer2[x2].PkRecibidos - nAntPKRecib2 < 0)
                            {
                                nAntPKRecib2 = aStatsServer2[x2].PkRecibidos
                            }

                            aPKRecib2.push(aStatsServer2[x2].PkRecibidos - nAntPKRecib2)
                        }
                        else 
                        {
                            aPKRecib2.push(0)
                        }

                        aPKRecibLabels.push(aStatsServer2[x2].fecha.substr(11, 5))
                        
                        //nAntPKRecib = aStatsServer1[x2].PkRecibidos 
                        nAntPKRecib2 = (aStatsServer2[x2].PkRecibidos ? aStatsServer2[x2].PkRecibidos : nAntPKRecib2) 

                        // Paquetes enviados, también saca la diferencia entre item e item 
                        //aPKEnviados.push(aStatsServer1[x2].PkEnviados - nAntPKEnv)
                        if (aStatsServer2[x2].PkEnviados)
                        {
                            if (aStatsServer2[x2].PkEnviados - nAntPKEnv2 < 0)
                            {
                                nAntPKEnv2 = aStatsServer2[x2].PkEnviados
                            }

                            aPKEnviados2.push(aStatsServer2[x2].PkEnviados - nAntPKEnv2)
                        }
                        else 
                        {
                            aPKEnviados2.push(0)
                        }

                        aPKEnviadosLabels.push(aStatsServer2[x2].fecha.substr(11, 5))
                        
                        //nAntPKEnv = aStatsServer1[x2].PkEnviados 
                        nAntPKEnv2 = (aStatsServer2[x2].PkEnviados ? aStatsServer2[x2].PkEnviados : nAntPKEnv2) 
                    } 
                    
                    aCPUBussyTitulo.push("%CPU Ocupado (Serv. Viejo)")
                    aCPUBussyTitulo2.push("%CPU Ocupado (Serv. Nuevo)")
                    aConnectionsTitulo.push("Cantidad de Conexiones /seg (Serv. Viejo)")
                    aConnectionsTitulo2.push("Cantidad de Conexiones /seg (Serv. Nuevo)")
                    aIOBussyTitulo.push("% I/O Ocupado (Serv. Viejo)")
                    aIOBussyTitulo2.push("% I/O Ocupado (Serv. Nuevo)")
                    aLectTitulo.push("Lecturas en Disco (Serv. Viejo)")
                    aLectTitulo2.push("Lecturas en Disco (Serv. Nuevo)")
                    aEscritTitulo.push("Escrituras en Disco (Serv. Viejo)")
                    aEscritTitulo2.push("Escrituras en Disco (Serv. Nuevo)")
                    aPKRecibTitulo.push("Paquetes Recibidos (Serv. Viejo)")
                    aPKRecibTitulo2.push("Paquetes Recibidos (Serv. Nuevo)")
                    aPKEnviadosTitulo.push("Paquetes Enviados (Serv. Viejo)")
                    aPKEnviadosTitulo2.push("Paquetes Enviados (Serv. Nuevo)")
                }  

                oListaStats = <div className='col-md-12 px-0 scroll-v stats'> 
                                    <h6 className='text-center py-0 my-0'>% Uso de CPU</h6>

                                    <ChartLines datos={ [aCPUBussy2] }
                                                titulos={ [aCPUBussyTitulo2] }
                                                colores={ ["green"] }
                                                labels={ aCPUBussyLabels }
                                                solografico={ true }
                                                sinanimation={ true }
                                                height={ 160 }
                                                width={ 400 }
                                                pointradius={ 1 } /> 

                                    <div className='clear'></div>
                                    <h6 className='text-center py-0 my-0'>Cantidad de Conexiones /seg</h6>

                                    <ChartLines datos={ [aConnections2] }
                                                titulos={ [aConnectionsTitulo2] }
                                                colores={ ["green"] }
                                                labels={ aConnectionsLabels }
                                                solografico={ true }
                                                sinanimation={ true }
                                                height={ 160 }
                                                width={ 400 }
                                                pointradius={ 1 } /> 

                                    <div className='clear'></div>
                                    <h6 className='text-center py-0 my-0'>% Uso de I/O</h6>

                                    <ChartLines datos={ [aIOBussy2] }
                                                titulos={ [aIOBussyTitulo2] }
                                                colores={ ["green"] }
                                                labels={ aIOBussyLabels }
                                                solografico={ true }
                                                sinanimation={ true }
                                                height={ 160 }
                                                width={ 400 }
                                                pointradius={ 1 } /> 

                                    <div className='clear'></div>
                                    <h6 className='text-center py-0 my-0'>Lecturas en Disco</h6>

                                    <ChartLines datos={ [aLect2] }
                                                titulos={ [aLectTitulo2] }
                                                colores={ ["green"] }
                                                labels={ aLectLabels }
                                                solografico={ true }
                                                sinanimation={ true }
                                                height={ 160 }
                                                width={ 400 }
                                                pointradius={ 1 } /> 

                                    <div className='clear'></div>
                                    <h6 className='text-center py-0 my-0'>Escrituras en Disco</h6>

                                    <ChartLines datos={ [aEscrit2] }
                                                titulos={ [aEscritTitulo2] }
                                                colores={ ["green"] }
                                                labels={ aEscritLabels }
                                                solografico={ true }
                                                sinanimation={ true }
                                                height={ 160 }
                                                width={ 400 }
                                                pointradius={ 1 } /> 

                                    <div className='clear'></div>
                                    <h6 className='text-center py-0 my-0'>Paquetes Recibidos</h6>

                                    <ChartLines datos={ [aPKRecib2] }
                                                titulos={ [aPKRecibTitulo2] }
                                                colores={ ["green"] }
                                                labels={ aPKRecibLabels }
                                                solografico={ true }
                                                sinanimation={ true }
                                                height={ 160 }
                                                width={ 400 }
                                                pointradius={ 1 } /> 

                                    <div className='clear'></div>
                                    <h6 className='text-center py-0 my-0'>Paquetes Enviados</h6>

                                    <ChartLines datos={ [aPKEnviados2] }
                                                titulos={ [aPKEnviadosTitulo2] }
                                                colores={ ["green"] }
                                                labels={ aPKEnviadosLabels }
                                                solografico={ true }
                                                sinanimation={ true }
                                                height={ 160 }
                                                width={ 400 }
                                                pointradius={ 1 } /> 
                              </div>
            }

            oPestanaServer = !this.state.verEstadoServer
                                ?   <div className={ "server-pegado " + aServer.estado }
                                         onClick={ () => this.setState({ verEstadoServer: true }) }>
                                        <div className='girar90deg text-nowrap text-center'>
                                            <FontAwesomeIcon icon={ faMapMarkerAlt } /> { aServer.abrev }
                                        </div>
                                    </div>
                                :   <div className={ "server-pegado p-0 abierto " + aServer.estado + (aStatsServer2.length && this.state.seccion === 2 ? " graficos" : "") }
                                         onClick={ () => this.setState({ verEstadoServer: false }) }>

                                        <button onClick={ () => this.CerrarStats() } 
                                                className='cerrar' 
                                                style={{ zIndex: "40" }}>
                                            <FontAwesomeIcon icon={ faTimes } />
                                        </button>

                                        { this.state.seccion === 1
                                            ?   <div className='estado-servidor'>
                                                    <div className='tarjeta-anuncios p-2 fondo-blanco mt-2 mx-2 text-normal' 
                                                        style={{ opacity: "0.8" }}>
                                                        
                                                        Estás en el servidor: 
                                                        <div className={ 'd-flex align-items-center text-nowrap text-bold nombre ' + aServer.estado }>
                                                            <FontAwesomeIcon icon={ faServer } size="2x" className='mr-2' /> <div className='text-black text-bold'>{ aServer.server.split(",")[0] }</div>
                                                        </div>

                                                        <div className='d-flex text-small align-items-center'>
                                                            Estado: 
                                                            <div className={ 'ml-2 mr-1 punto-estado ' +  aServer.estado }>&nbsp;</div>
                                                            <div className='text-upper ml-1'>{ aServer.estado }</div> 
                                                        </div>

                                                        <div className='ml-1 text-smaller'>
                                                            { aServer.observaciones ? <FontAwesomeIcon className='mr-1' icon={ faInfoCircle  } /> : [] }
                                                            { aServer.observaciones }
                                                        </div> 
                                                    </div>

                                                    <div className='d-flex'>
                                                        <div className='tarjeta-anuncios p-2 fondo-blanco mt-1 ml-2' 
                                                            style={{ opacity: "0.8" }}>
                                                            <div className='d-flex align-items-center text-nowrap'>
                                                                <FontAwesomeIcon icon={ faDesktop } className='mr-1' /> { aServer.descrip }
                                                            </div>
                                                        </div>
                                                        <div className='tarjeta-anuncios p-2 fondo-blanco mt-1' 
                                                            style={{ opacity: "0.8" }}>
                                                            <div className='d-flex align-items-center text-nowrap text-bold'>
                                                                <FontAwesomeIcon icon={ faMapMarkerAlt } className='mr-1' /> { aServer.ubicacion }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            :   <div className='stats-servidor' 
                                                     onClick={ (e) => e.stopPropagation() } 
                                                     style={{ opacity: "0.8" }}>
                                                    
                                                    <div className='d-flex fondo-blanco'>
                                                        { oListaStats }
                                                    </div> 
                                                </div>
                                        }

                                        { this.state.stats
                                            ?   <div className='d-flex ml-3 mt-1' onClick={ (e) => e.stopPropagation() }>
                                                    <button onClick={ () => this.setState({ seccion: 1 }) } className='no-select activo'>
                                                        <FontAwesomeIcon icon={ faServer } size={ this.state.seccion === 1 ? "lg" : "1x" } />
                                                    </button>
                                                    <button onClick={ () => this.setState({ seccion: 2 }, () => this.ConsultarStats()) } className='no-select activo'>
                                                        <FontAwesomeIcon icon={ faChartPie } size={ this.state.seccion === 2 ? "lg" : "1x" } />
                                                    </button>
                                                    
                                                    { aStatsServer2.length && this.state.seccion === 2
                                                        ?   <div>
                                                                <button className={ this.state.periodo === "15M" ? "text-resaltado no-select" : "text-gray" } 
                                                                        onClick={ () => this.CambiarPeriodo("15M") }>
                                                                    15min
                                                                </button>
                                                                <button className={ this.state.periodo === "30M" ? "text-resaltado no-select" : "text-gray" } 
                                                                        onClick={ () => this.CambiarPeriodo("30M") }>
                                                                    30min
                                                                </button>
                                                                <button className={ this.state.periodo === "1H" ? "text-resaltado no-select" : "text-gray" } 
                                                                        onClick={ () => this.CambiarPeriodo("1H") }>
                                                                    1hs
                                                                </button>
                                                                <button className={ this.state.periodo === "3H" ? "text-resaltado no-select" : "text-gray" } 
                                                                        onClick={ () => this.CambiarPeriodo("3H") }>
                                                                    3hs
                                                                </button>
                                                                <button className={ this.state.periodo === "6H" ? "text-resaltado no-select" : "text-gray" } 
                                                                        onClick={ () => this.CambiarPeriodo("6H") }>
                                                                    6hs
                                                                </button>
                                                                <button className={ this.state.periodo === "12H" ? "text-resaltado no-select" : "text-gray" } 
                                                                        onClick={ () => this.CambiarPeriodo("12H") }>
                                                                    12hs
                                                                </button>
                                                                <button className={ this.state.periodo === "1D" ? "text-resaltado" : "text-gray" } 
                                                                        onClick={ () => this.CambiarPeriodo("1D") }>
                                                                    Hoy
                                                                </button>
                                                            </div>
                                                        :   []
                                                    }

                                                    <div className='d-flex text-white'>
                                                        { this.state.cargandoStats }
                                                    </div>
                                                </div>
                                            :   []
                                        }

                                        { this.state.esperando
                                            ?   <div className='barra-espera' key={ "b-" + (aStatsServer2[0] ? aStatsServer2[0].Bussy : 0) }></div>
                                            :   []
                                        }
                                    </div> 
        }

        return (
            <div className={ "pie-pagina fondo-pie " + cVisible }>
                { this.state.Noticias }
                { oPestanaServer }
                
                <div className='col-md-12 d-flex justify-content-center flex-sm-column'>
                    <div className="mx-2 transparent text-white my-4 pt-2 text-center">
                        <div className="col-md-12">
                            <a href="/inicio" 
                               className="transparent text-white text-center text-xs-left col-xs-12 text-bold" >
                                  <FontAwesomeIcon icon={ faHome } /> IR AL INICIO
                            </a>
                        </div>
                    </div>

                    <div className={ "mx-2 transparent text-white my-4 " + (this.state.pagar ? "invisible" : "") }>
                        <div className="col-md-12">
                            <button className="data-group transparent text-white text-left text-xs-center" 
                                    to="" 
                                    data-toggle="collapse" 
                                    data-target="#dat-pie" 
                                    aria-expanded="false" 
                                    aria-controls="dat-pie" >
                                <FontAwesomeIcon icon={ faPhone } /> ATENCIÓN A CLIENTE
                            </button>
                        </div>
                        <div className="collapse mx-4 transparent" id="dat-pie">
                            <div className="card card-body transparent sin-borde">
                                <div className="text-left">
                                    <ul className="pl-0">
                                        <li>
                                            <a className="text-white" href="https://possum.com.ar/ayuda" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={ faQuestionCircle } /> Centro de ayuda
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-white" href={ "https://possum.com.ar/ayuda/" + cPantalla } target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={ faQuestionCircle } /> Ayuda para esta pantalla
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-white" href="https://possum.com.ar/ayuda/FAQ">
                                                <FontAwesomeIcon icon={ faQuestionCircle } /> Preguntas Frecuentes
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-white" href="https://api.whatsapp.com/send?phone=543515580020" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={ faPhoneAlt } /> Escribinos a WhatsApp
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-white" href="tel:+543515580020">
                                                <FontAwesomeIcon icon={ faPhoneAlt } /> Llamanos (+54) 351 - 5580020 
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-white" href="mailto:info@softplacesoftware.com.ar">
                                                <FontAwesomeIcon icon={ faEnvelope } /> Escribinos info@softplacesoftware.com.ar  
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mx-2 transparent text-white my-4">
                        <div className="col-md-12">
                            <button className="data-group transparent text-white text-left text-xs-center" 
                                    to="" 
                                    data-toggle="collapse" 
                                    data-target="#dat-pie" 
                                    aria-expanded="false" 
                                    aria-controls="dat-pie" >
                                <FontAwesomeIcon icon={ faUserCircle } /> NOSOTROS
                            </button>
                        </div>
                        <div className="collapse mb-3 ml-3 mr-3" id="dat-pie">
                            <div className="card card-body transparent sin-borde">
                                <div className="text-left">
                                    <ul className="pl-0">
                                        <li>
                                            <button className="text-white" onClick={ () => window.location = "/inicio#nosotros" }>
                                                <FontAwesomeIcon icon={ faQuestionCircle } /> Quienes Somos
                                            </button>
                                        </li>
                                        <li>
                                            <button className="text-white" onClick={ () => window.location = "/inicio#precios" } >
                                                <FontAwesomeIcon icon={ faChartPie } /> Otros Productos
                                            </button>
                                        </li>
                                        <li>
                                            <button className="text-white" onClick={ () => window.location = "/inicio#ofrecemos" } >
                                                <FontAwesomeIcon icon={ faQuestionCircle } /> ¿Que te ofrecemos?
                                            </button>
                                        </li>
                                        <li>
                                            <button className="text-white">
                                                <FontAwesomeIcon icon={ faCommentDollar } /> Datos Necesarios
                                            </button>
                                        </li>
                                        <li>
                                            <a href="https://possum.com.ar/inicio" target="_blank" rel="noopener noreferrer" className="text-white mr-5">
                                                <img src="/images/logo-blanco-transparente.png" 
                                                    width="20"
                                                    className="ml-1 mr-2"
                                                    alt="SoftPlace Software" />
                                                ...mas sobre POSSUM
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-white px-2" href="/version">
                                                <FontAwesomeIcon icon={ faSortNumericUpAlt } /> Ver Versionado
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mx-2 transparent text-white my-4">
                        <div className="col-md-12">
                            <button className="data-group transparent text-white text-left text-xs-center" 
                                    to="" 
                                    data-toggle="collapse" 
                                    data-target="#dat-pie" 
                                    aria-expanded="false" 
                                    aria-controls="dat-pie" >
                                <FontAwesomeIcon icon={ faUser } /> MI CUENTA
                            </button>
                        </div>
                        <div className="collapse mb-3 ml-3 mr-3" id="dat-pie">
                            <div className="card card-body transparent sin-borde">
                                <div className="text-left">
                                    <ul className="pl-0">
                                        <li>
                                            <a className="text-white" href="/cuenta-corriente">
                                                <FontAwesomeIcon icon={ faDollarSign } /> 
                                                
                                                Mi Cuenta
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-white" href="/cuenta-corriente">
                                                <FontAwesomeIcon icon={ faDollarSign } /> Pagar 
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-white" href="/mis-datos">
                                                <FontAwesomeIcon icon={ faUserCircle } /> Mis datos
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-white" href="/mis-servicios">
                                                <FontAwesomeIcon icon={ faCogs } /> Mis Servicios Contratados
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-white" href="/equipos">
                                                <FontAwesomeIcon icon={ faServer } /> Mis equipos
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <div className="clear borde-blanco"></div>
                
                <div className="col-md-12 col-lg-12 col-xs-12 text-center transparent mt-4 logo-pie">
                    <a href="https://softplacesoftware.com.ar" 
                       target="_blank" 
                       rel="noopener noreferrer" 
                       className="text-white col-lg-12 text-center">
                        <img src="/images/SP-Logo-blanco.png" 
                             className="col-md-2 col-xs-8 float-none"
                             alt="SoftPlace Software" />
                        <div className="mt-3">
                            SoftPlace Software <FontAwesomeIcon icon={ faRegistered } /> 2007 - { fecha.getFullYear() } 
                        </div>
                    </a>
                </div>

                <button className="text-gray" 
                        style={{ position: "fixed", 
                                 bottom: "10px", 
                                 right: "12px", 
                                 border: "1px solid gray", 
                                 borderRadius: "50%", 
                                 zIndex: "1000" }}
                        onClick={ () => this.Top() }>
                    <FontAwesomeIcon icon={ faChevronUp } />
                </button>

                <a href="https://wa.me/message/5TYIEBYPVWZFP1"
                   style={{ position: "fixed", 
                            bottom: "45px", 
                            right: "5px", 
                            zIndex: "1000",
                            borderRadius: "50%", 
                            backgroundColor: "rgb(13, 193, 67)", 
                            padding: "7px 7px 9px 9px",
                            opacity: "0.75" }}
                   className={ "bounce-in " + (this.state.pagar || this.state.ayuda || this.state.switch ? "invisible" : "") }
                   target="_blank" 
                   rel="noopener noreferrer" >
                        <img src="/images/wa.png" alt="softplace software" width="30" />
                </a>
                
                <div className="clear"></div>

                <input type="text" id="Copiar" style={{ display: "none" }} />
                
                <ToastContainer /> 
            </div>
        );
    }

    componentDidMount()
    {
        const aURL = window.location.href.split("/")
        const per = new Permisos()
        
        if (aURL[2] !== "localhosta:3000")
        {
            this.BuscarNoticias()
        }
        
        if(aURL[3].toLocaleLowerCase() === "pagar")
        {
            this.setState({ pagar: true })
        }

        if(aURL[3].toLocaleLowerCase() === "ayuda")
        {
            this.setState({ ayuda: true })
        }

        if(aURL[3].toLocaleLowerCase() === "switch")
        {
            this.setState({ switch: true })
        }

        per.VerPermisos("Permiso para ver facturas de SoftPlace").then((rta) =>
        {
            if (rta.status === 200 || typeof rta.Acceso !== "undefined")
            {
                this.setState({ permisos: rta })
            } 
        }) 
    }

    Top()
    {
        let scroll = Scroll.animateScroll

        scroll.scrollToTop() 
    }

    BuscarNoticias()
    {
        const spg = new DatosSPG()
        const nCliente = spg.VerDato("Cliente-SP")
        const cServer = spg.VerDato("bdServer")
        const bDeudaVencida = spg.VerDato("Deuda-Vencida")
        const nBD = parseInt(spg.VerDato("idBD"))
        const aURL = window.location.href.split("/")

        let bIndex = false
        
        if(aURL[3].toLocaleLowerCase() === "" && aURL.length === 4)
        {
            bIndex = true 
        }

        if (nBD === 2 || nBD === 17)
        {
            this.setState({ stats: true })
        }

        // el cliente está configurado y no es desarrollo
        if (nCliente)
        {
            const aParametros = [{
                                    "nombre": "idBD",
                                    "valor": nBD
                                },
                                {
                                    "nombre": "Cliente-SP",
                                    "valor": nCliente
                                },
                                {
                                    "nombre": "bdServer",
                                    "valor": cServer ? cServer : ""
                                },
                                {
                                    "nombre": "BuscarDeuda",
                                    "valor": (bIndex || bDeudaVencida === "1" ? 1 : 0) // si es el index, siempre busca la deuda, y si esta vencida la deuda tambien 
                                }]
            
            spg.LeerAPI("consultas/", "ver-noticias", aParametros).then((rta) => 
            { 
                if(rta.status === 200)
                {
                    let oNoticias = []
                    
                    // Siempre prioriza la actualizacion, antes q la deuda
                    if (rta.version !== version.nroversion)
                    {
                        // si la actualización es obligatoria, instantáneamente elimina la cache y actualiza el navegador, sino, pregunta cuando lo quiere actualizar
                        if (rta["actualizacion-obligatoria"])
                        {
                            if (nBD !== 17)
                            {
                                this.EliminarCache()
                            }
                        }
                        else 
                        {
                            oNoticias.push(<div key="noticia-3" 
                                                className="aviso-inferior slide-in-bottom verde">
                                                
                                                <div onClick={ () => this.EliminarCache() }
                                                    style={{ cursor: "pointer" }}>
                                                    <div className="text-white text-center text-upper">
                                                        Hay una nueva actualización!
                                                        <span className="text-sp text-bold borde-blanco ml-3 p-1 px-4 fondo-blanco" 
                                                            onClick={ () => this.EliminarCache() }
                                                            style={{ cursor: "pointer" }}> 
                                                            <FontAwesomeIcon icon={ faRedo } /> Hacé click para actualizar a la versión { rta.version }
                                                        </span>
                                                    </div> 
                                                </div>

                                                <button onClick={ () => this.setState({ Noticias: [] }) }
                                                        className="float-right text-white">
                                                    <FontAwesomeIcon icon={ faTimes} />
                                                </button>
                                            </div>)
                        }
                    }
                    else 
                    { 
                        // Siempre prioriza la actualizacion de la cache, antes q la deuda
                        const aVersionCacheNueva = rta["version-cache"];
                        const aVersionCacheActual = spg.VerDato("version-cache", true);
                        const nUsuario = spg.VerDato("idUsuario");

                        if (aVersionCacheNueva.version !== aVersionCacheActual.version && nUsuario && aURL[3].toLocaleLowerCase() !== "limpiar-cache")
                        {
                            oNoticias = <div key="noticia-5" 
                                                className="aviso-inferior slide-in-bottom">
                                                
                                                <a href="/limpiar-cache/automatico">
                                                    <div className="text-white text-center">
                                                        <FontAwesomeIcon icon={ faInfoCircle } /> Otro dispositivo hizo cambios en la estructura de la base de datos. Es necesario limpiar la caché.

                                                        <span className="text-sp text-bold borde-blanco ml-3 p-1 px-4 fondo-blanco"> 
                                                            LIMPIAR CACHÉ  
                                                        </span>
                                                    </div> 
                                                </a>

                                                <button onClick={ () => this.setState({ Noticias: [] }) }
                                                        className="float-right text-white">
                                                    <FontAwesomeIcon icon={ faTimes} />
                                                </button>
                                            </div>
                        }
                        else 
                        {
                            const aPermisos = this.state.permisos 
                            const bAdmin = spg.VerDato("usuAdmin") && spg.VerDato("usuAdmin") === "1" ? true : false

                            if (rta.Deuda > 0 && (aPermisos.Acceso || bAdmin))
                            {
                                const dFecha = new Fecha()
                                
                                // la fecha de hoy es mayor a la fecha de Vencimiento?
                                if (dFecha.FormatoIng() > dFecha.FormatoIng(rta.FechaVenc))
                                {
                                    spg.GuardarDato("Deuda-Vencida", 1)

                                    oNoticias.push(<div key="noticia-1" 
                                                        className="aviso-inferior slide-in-bottom rojo">
                                                        
                                                        <a href="/cuenta-corriente" 
                                                            target="_blank" 
                                                            rel="noopener noreferrer">
                                                                    <div className="text-white text-center text-upper">
                                                                        <FontAwesomeIcon icon={ faIdCard } /> Tu factura esta VENCIDA, para pagar haga click AQUÍ (Recordá que tu dominio web no será renovado si tenés deuda vencida). Tu deuda actual es $ { rta.Deuda.toFixed(2) } 
                                                                        <span className="text-red text-bold borde-blanco ml-3 p-1 px-4 fondo-blanco"> 
                                                                            PAGAR 
                                                                        </span>
                                                                    </div> 
                                                        </a>

                                                        <button onClick={ () => this.setState({ Noticias: [] }) }
                                                                className="float-right text-white">
                                                            <FontAwesomeIcon icon={ faTimes} />
                                                        </button>
                                                    </div>)
                                }
                                else
                                { 
                                    if (bIndex)
                                    {
                                        spg.GuardarDato("Deuda-Vencida", 0)
                                        
                                        oNoticias.push(<div key="noticia-2" 
                                                            className="aviso-inferior slide-in-bottom">
                                                            
                                                            <a href="/cuenta-corriente" 
                                                                target="_blank" 
                                                                rel="noopener noreferrer">
                                                                        <div className="text-white text-center text-upper">
                                                                            <FontAwesomeIcon icon={ faIdCard } /> Tu factura ya está disponible, para pagar haga click AQUÍ. Tu saldo actual es $ { rta.Deuda.toFixed(2) }
                                                                            <span className="text-sp text-bold borde-blanco ml-3 p-1 px-4 fondo-blanco"> 
                                                                                PAGAR
                                                                            </span>
                                                                        </div> 
                                                            </a>

                                                            <button onClick={ () => this.setState({ Noticias: [] }) }
                                                                    className="float-right text-white">
                                                                <FontAwesomeIcon icon={ faTimes} />
                                                            </button>
                                                        </div>)
                                    }
                                }
                            }
                            else
                            {
                                spg.GuardarDato("Deuda-Vencida", 0)
                            }

                            const aBDMsj = rta.BDMensaje
                            
                            if (rta.Mensaje && nBD && (aBDMsj.includes(nBD) || rta.BDMensaje.length === 0) && bIndex && rta.Deuda <= 0)
                            {
                                oNoticias = <div key="noticia-4" 
                                                className="aviso-inferior slide-in-bottom">
                                                
                                                <a href={ rta.urlMensaje ? rta.urlMensaje : "/" } target={ rta.target }>
                                                    <div className="text-white text-center">
                                                        <FontAwesomeIcon icon={ faInfoCircle } /> { rta.Mensaje }
                                                    </div> 
                                                </a>

                                                <button onClick={ () => this.setState({ Noticias: [] }) }
                                                        className="float-right text-white">
                                                    <FontAwesomeIcon icon={ faTimes} />
                                                </button>
                                            </div> 
                            }
                        }
                    }
                    
                    this.setState({ Noticias: oNoticias, server: rta["server"] })
                }
            })
        }
        else
        {
            /*const cCertificado = spg.VerDato("Certificado-SPG")
            
            // Si tiene certificado pero la base de datos no esta configurada, hay que avisar (a menos que sea desarrollo)
            if ((nBD !== 17 && nBD !== 2 && nBD !== 1) || !nBD || !cCertificado)
            {
                this.setState({ Noticias: <div key="noticia-4" 
                                                className="aviso-inferior slide-in-bottom">
                                                
                                                <a href="/cuenta-corriente" 
                                                    target="_blank" 
                                                    rel="noopener noreferrer">
                                                            <div className="text-white text-center text-upper">
                                                                <FontAwesomeIcon icon={ faIdCard } /> Tu cuenta no está configurada correctamente, 
                                                                por favor hágalo saber a SoftPlace para que terminen de configurar correctamente su cuenta
                                                            </div> 
                                                </a>

                                                <button onClick={ () => this.setState({ Noticias: [] }) }
                                                        className="float-right text-white">
                                                    <FontAwesomeIcon icon={ faTimes} />
                                                </button>
                                            </div> })
            }*/
        }
    }

    CambiarPeriodo(cPer)
    {
        clearInterval(this.state.intervalo)

        this.setState({ periodo: cPer, intervalo: 0 }, () => this.ConsultarStats())
    }

    ConsultarStats() 
    { 
        if (!this.state.intervalo)
        {
            this.setState({ esperando: false })

            const spg = new DatosSPG();
            const aParametros = [{
                                    "nombre": "desde",
                                    "valor": this.state.periodo
                                }]
            
            spg.LeerAPI("consultas/", "ver-stats-servidor", aParametros).then((jRta) => 
            {
                this.setState({ esperando: true })

                if (jRta.status === 200)
                { 
                    this.setState({ statsServer1: jRta[0], statsServer2: jRta[1], cargandoStats: [] })
                } 
                else 
                {
                    if (jRta.status !== 409)
                    {
                        this.setState({ cargandoStats: <div><FontAwesomeIcon icon={ faExclamationTriangle } /> Error al consultar</div> })
                    }
                }
            })
            
            let nInt = setInterval(() => 
            {
                if (this.state.verEstadoServer)
                {
                    this.setState({ cargandoStats: <div><FontAwesomeIcon icon={ faSpinner } spin /> Buscando Stats...</div> })
                    
                    spg.LeerAPI("consultas/", "ver-stats-servidor", aParametros).then((jRta) => 
                    {
                        if (jRta.status === 200)
                        {
                            //for (let x1 = 0; x1 < jRta.length; x1++)
                            //{
                            //    aStats.push(jRta[x1])
                            //}

                            this.setState({ statsServer1: jRta[0], statsServer2: jRta[1], cargandoStats: [] })
                        } 
                        else 
                        {
                            if (jRta.status !== 409)
                            {
                                this.setState({ cargandoStats: <div><FontAwesomeIcon icon={ faExclamationTriangle } /> Error al consultar</div> })
                            }
                        }
                    })
                }
            }, 60000); // Cada 1 minuto se renueva

            this.setState({ intervalo: nInt })
        }
    }

    CerrarStats()
    {
        this.setState({ verEstadoServer: false })

        if (this.state.intervalo)
        {
            clearInterval(this.state.intervalo)
        }
    }

    EliminarCache()
    {
        if (window.caches)
        {
            // busca todas las caches que haya en el navegador
            /*caches.keys().then((keys) => 
            {
                // Limpia todas las cachés que pueda haber, aunque sean viejas
                for (let i = 0; i < keys.length; i++)
                {
                    caches.has(keys[i]).then(() => 
                    {
                        caches.delete(keys[i]).then(() => window.location.reload(true));
                    })
                }
            })*/

            window.location.reload(true)
        }
        else 
        {
            window.location.reload(true)
        }
    } 
}

export default Pie;