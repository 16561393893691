import React from 'react';

import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faChartPie, faDollarSign, faExclamationTriangle, faHome, faInfoCircle, faLock, faUnlock, faChevronRight, faBroom, 
         faWrench, faTag, faTags, faDolly, faBars, faUserAlt, faShieldAlt, faQrcode, faStore, faHdd, faKey, faCreditCard, faCashRegister, 
         faLayerGroup, faFileInvoiceDollar, faFileInvoice, faUsers, faShippingFast, faUniversity, faMapMarkedAlt, faGlobeAmericas, 
         faPercentage, faIdCard, faCopy, faAsterisk, faMobileAlt, faUserTie, faBuilding, faUserTag, faCogs, faToolbox, faUserShield, 
         faImages, faFileImport, faGift, faQuestionCircle, faMoneyCheckAlt, faChartArea, faSearch, faPencilAlt, 
         faCalendarAlt, 
         faRuler} from '@fortawesome/free-solid-svg-icons';

import DatosSPG from './funciones';
import Configuraciones from '../objetos/configuraciones';
import Comprobantes from '../componentes/comprobantes';

class Menu extends React.Component
{
    state = { 
              MostrarTexto: false,
              encontrados: [],
              key: 0
            } 

    render()
    {
        const spg = new DatosSPG();

        if (!this.props.pagar)
        {
            const conf = new Configuraciones()

            let cTituloMaterial = "Material"
            
            if (conf.list && conf.list.TituloMateriaPrima)
            {
                cTituloMaterial = conf.list.TituloMateriaPrima

                if (cTituloMaterial === "Material")
                {
                    // lo cambia aquí a plural, porque sólo se utiliza asi
                    cTituloMaterial = "Materiales"
                }
            }
            
            if (this.props.reporte || spg.VerDato("idUsuario"))
            {
                const aItemsEncontrados = this.state.encontrados
                let oBusqueda = []
                
                if (aItemsEncontrados.length)
                {
                    for (let x2 = 0; x2 < aItemsEncontrados.length; x2++)
                    {
                        oBusqueda.push(this.DropDown(faSearch, aItemsEncontrados[x2].texto, aItemsEncontrados[x2].link))
                    }
                }

                const comp = new Comprobantes()

                // Menú resumido, es decir, Licencia barata
                if (conf.list && conf.list.MenuResumido)
                {
                    if (conf.list && !conf.list.MenuLight)
                    {
                        return(
                                <div id="menu" onMouseEnter={ () => this.AbrirDropDownGral() } 
                                            onMouseLeave={ () => this.CerrarDropDown() }>
                                    <nav>
                                        <ul style={{ paddingBottom: "50px" }}>
                                            <li className="menu-spg superior fondo-sp" id="superior">
                                                <div><FontAwesomeIcon icon={ faBars } size="lg" /></div>
                                            </li>

                                            { this.Logo() }
                                            { this.SubMenu(faHome,                          "Inicio",   false, false, "/") }
                                            { this.SubMenu(faSearch,                        "Buscar",   false, true, "ddw-search") }
                                                <div className="dropdown-menu scroll-v h-50" id="ddw-search">
                                                    { this.DropDown(faPencilAlt,            "Buscar texto", "") }
                                                    
                                                    <input placeholder='¿Que estás buscando?' 
                                                        autoComplete="off" 
                                                        id="buscar" 
                                                        className='px-1 mx-2 border-none border-b-dot mb-3' 
                                                        onKeyUp={ (e) => this.Buscar(e) } />
                                                    
                                                    { oBusqueda }
                                                </div>
                                            { this.SubMenu(faDollarSign,             "Caja",             false, true, "ddw-valores") }
                                                <div className="dropdown-menu" id="ddw-valores">
                                                    { this.DropDown(faCashRegister,  "Registrar comprobantes de ventas", "/facturacion?clave=" + comp.GetClave()) }
                                                    { this.DropDown(faHdd,           "Facturas electrónicas pendientes", "/fae-pendiente") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faChartPie,      "Consulta de Comprobantes", "/consulta-comprobantes") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faQrcode,        "Cobrar Ahora", "/cobrar") }
                                                </div>
                                            { this.SubMenu(faWrench,                 "Configuraciones",  false, true, "ddw-configuraciones") }
                                                <div className="dropdown-menu" id="ddw-configuraciones">
                                                    { this.SubMenu(faTag,                 "Productos",  false, true, "ddw-pr-configuraciones-prod", false, "pl-0") }
                                                        <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-prod">
                                                            { this.DropDown(faTag,        "Productos", "/listado/productos", "fondo-sp") }
                                                            { this.DropDown(null,            "-", "") }
                                                            { this.DropDown(faTags,        "Categorías", "/listado/tipos-productos", "fondo-sp") }
                                                            { this.DropDown(faTags,        "Sub-Categorías", "/listado/sub-tipos-productos", "fondo-sp") }
                                                            { this.DropDown(faDolly,        "" + cTituloMaterial, "/listado/materias-primas", "fondo-sp") }
                                                            { this.DropDown(null,           "-", "") }
                                                            { this.DropDown(faRuler,        "Unidades de medida", "/listado/unidades") }
                                                            { this.DropDown(faTag,          "Propiedades", "/listado/propiedades") }
                                                            { this.DropDown(faImages,       "Banners de Categorías de la Tienda", "/banners") }
                                                            { this.DropDown(faImages,       "Optimizá tus imágenes para la web", "/achicar-imagenes") }
                                                            { this.DropDown(null,            "-", "") }
                                                            { this.DropDown(faFileImport,   "Importar Productos desde archivo CSV", "/importar-productos") }
                                                            { this.DropDown(faDollarSign,   "Actualización masiva de precios", "/actualizar-precios", "fondo-sp") }
                                                            { this.DropDown(faDollarSign,   "Cambiar moneda en productos", "/cambiar-moneda", "fondo-sp") }
                                                        </div>
                                                    { this.SubMenu(faUsers,                 "Entidades",  false, true, "ddw-pr-configuraciones-ent", false, "pl-0") }
                                                        <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-ent">
                                                            { this.DropDown(faUserTie,    (conf.list.TituloCliente ? conf.list.TituloCliente : "Clientes"), "/listado/clientes") }
                                                            { this.DropDown(null,              "-", "") }
                                                            { this.DropDown(faUserTie,    "Tipos de " + (conf.list.TituloCliente ? conf.list.TituloCliente : "Clientes"), "/listado/tipos-entidades/clientes") }
                                                            { this.DropDown(null,              "-", "") }
                                                            { this.DropDown(faGift,  "Configurar Fidelización de " + (conf.list.TituloCliente ? conf.list.TituloCliente : "Clientes"), "/fidelizacion") }
                                                        </div>
                                                    { this.SubMenu(faShippingFast,      "Envíos",  false, true, "ddw-pr-configuraciones-env", false, "pl-0") }
                                                        <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-env">
                                                            { this.DropDown(faShippingFast,    "Transportes", "/listado/transportes") }
                                                            { this.DropDown(faMapMarkedAlt,    "Zonas", "/listado/zonas-envios") } 
                                                            { this.DropDown(null,            "-", "") }
                                                            { this.DropDown(faGlobeAmericas,    "Configurar Envíos", "/zonas-envios") }
                                                        </div>
                                                    { this.DropDown(faCreditCard,        "Monedas", "/listado/monedas") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.SubMenu(faPercentage,                 "Cobros y Descuentos",  false, true, "ddw-pr-configuraciones-conf", false, "pl-0") }
                                                        <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-conf">
                                                            { this.DropDown(faDollarSign,    "Unipago", "/config-pagos") }
                                                            { this.DropDown(faPercentage,    "Configurar Descuentos", "/config-descuentos") }
                                                            { this.DropDown(faPercentage,    "Cupones de Descuento", "/listado/cupones") }
                                                            { this.DropDown(faGift,          "Configurar Fidelización de " + (conf.list.TituloCliente ? conf.list.TituloCliente : "Clientes"), "/fidelizacion") }
                                                        </div> 
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.SubMenu(faCogs,                 "Otras Configuraciones",  false, true, "ddw-pr-configuraciones-otr", false, "pl-0 border-none") }
                                                        <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-otr" style={{ marginTop: "-100px" }}>
                                                            { this.DropDown(faStore,         "Perfiles de compra en la Tienda", "/config-perfiles-compra") }
                                                            { this.DropDown(faMobileAlt,      "Configurar WhatsApp Messaging", "/config-whatsapp-msj") }
                                                            { this.DropDown(faBuilding,      "Configurar datos de mi Negocio", "/mis-datos") }
                                                            { this.DropDown(null,            "-", "") }
                                                            { this.DropDown(faCogs,          "Panel de Control", "/panel-control") }
                                                        </div>
                                                    { this.DropDown(faBroom,        "Limpiar caché de Datos", "/limpiar-cache") }
                                                </div>
                                            { this.SubMenu(faBarcode,                "Stock",                        false, true, "ddw-stock") }
                                                <div className="dropdown-menu" id="ddw-stock" style={{ marginTop: "-200px" }}>
                                                    { this.DropDown(faBarcode,       "Registrar remito interno de Ajuste de Stock/Compra", "/remito-interno?clave=" + comp.GetClave()) }
                                                    { this.DropDown(faBarcode,       "Realizar Inventario", "/inventario?clave=" + comp.GetClave()) }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faTag,           "Productos", "/listado/productos") }
                                                    { this.DropDown(faBarcode,       "Consulta de Stock", "/consulta-stock") }
                                                    { this.DropDown(faBarcode,       "Consulta de Rápida de Productos", "/consulta-rapida-productos") }
                                                    { this.DropDown(null,                "-", "") }
                                                    { this.DropDown(faTag,                "Imprimir Etiquetas", "/imprimir-etiquetas") }
                                                </div>
                                            { this.SubMenu(faChartPie,               "Consultas",                    false, true, "ddw-consultas") }
                                                <div className="dropdown-menu" id="ddw-consultas">
                                                    { this.DropDown(faBarcode,      "Consulta de Stock", "/consulta-stock") }
                                                    { this.DropDown(faBarcode,      "Consulta de Rápida de Productos", "/consulta-rapida-productos") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faCopy,          "Consulta de Comprobantes", "/consulta-comprobantes") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faUserTie,      "Consulta de " + (conf.list.TituloCliente ? conf.list.TituloCliente : "Clientes"), "/consulta-entidades") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faChartPie,      "Consulta de Ventas WEB Pendientes", "/consulta-compras-web-pendientes") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faChartArea,      "Consulta de Navegación de la Tienda", "/consulta-stats-tienda") }
                                                    { this.DropDown(faChartArea,      "Consulta de Servicios Contratados", "/mis-servicios") }
                                                </div> 
                                            { this.SubMenu(faLock,                   "Seguridad",                    false, true, "ddw-seguridad") }
                                                <div className="dropdown-menu" id="ddw-seguridad" style={{ bottom: "30px" }}>
                                                    { this.DropDown(faLock,          <button className="text-white" onClick={ () => this.Salir() }>Cerrar Sesión - Cambiar de Usuario</button>, "") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faAsterisk,          "Cambiar Contraseña", "/cambiar-password") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faUserShield,          "Administrar mi Perfil", "/admin-usuario") } 
                                                    { this.DropDown(faUserShield,          "Usuarios", "/listado/usuarios") }
                                                    { this.DropDown(faUserShield,          "Permisos", "/listado/permisos") }
                                                    { this.DropDown(faUserShield,          "Tipos de Usuario", "/listado/tipos-usuarios") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faShieldAlt,          "Certificados", "/listado/certificados") }
                                                    { this.DropDown(faMobileAlt,          "Equipos Conectados", "/mis-equipos") }
                                                </div>
                                            { this.SubMenu(faExclamationTriangle,    "Pendientes",                   false, true, "ddw-pendientes") }
                                                <div className="dropdown-menu" id="ddw-pendientes" style={{ marginTop: "-50px" }}>
                                                    { this.DropDown(faHdd,                    "Consulta de Pendientes a Facturar Electrónicamente", "/fae-pendiente") }
                                                    { this.DropDown(null,                     "-", "") }
                                                    { this.DropDown(faExclamationTriangle,    "Ventas WEB pendientes", "/consulta-comprobantes?web=true&sin-relacion=true") }
                                                    { this.DropDown(faExclamationTriangle,    "Productos en alerta de stock", "/consulta-stock?bajostock=true") }
                                                    { this.DropDown(faExclamationTriangle,    "Ventas con pedido de devolución", "/consulta-comprobantes?web=true&sin-relacion=true&anulados=true&devueltos=true") }
                                                </div>
                                            { this.SubMenu(faInfoCircle,             "Info",                         false, true, "ddw-info") }
                                                <div className="dropdown-menu" id="ddw-info" style={{ marginTop: "-220px" }}>
                                                    { this.DropDown(faInfoCircle,    "Usuario: " + spg.VerDato("usuNombre"), "/usuarios") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faInfoCircle,    "Sucursal: " + spg.VerDato("sucDescrip"), "") }
                                                    { this.DropDown(faInfoCircle,    "Razón Social: " + spg.VerDato("sucRazonSocial"), "") }
                                                    { this.DropDown(faInfoCircle,    "Punto de Venta: " + spg.VerDato("pvDescrip"), "") }
                                                    { this.DropDown(faInfoCircle,    "Contribuyente: " + spg.VerDato("tyDescrip"), "") }
                                                    { this.DropDown(faInfoCircle,    "CUIT Empresa: " + spg.VerDato("sucCuit"), "") }
                                                    { this.DropDown(faInfoCircle,   <div className="d-block col-md-12"> 
                                                                                        <img src={ spg.Server() + "clientes-softplace/images/" + spg.VerDato("Cliente-SP") + ".jpg" } 
                                                                                            alt={ spg.VerDato("sucLogo") }
                                                                                            className='fondo-blanco'
                                                                                            onError={ (e) => e.target.src = process.env.PUBLIC_URL + "/images/isologotipo-joyerias.svg" }
                                                                                            height="35" /> Logo de la Empresa
                                                                                    </div>, "") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faInfoCircle,    "Pantalla: " + window.outerWidth + " x " + window.outerHeight, "") }
                                                    { this.DropDown(faInfoCircle,    "Sistema Operativo: No Disponible", "", "so-info") }
                                                    { this.DropDown(faInfoCircle,    "Navegador: No Disponible", "", "browser-info") }
                                                    { this.DropDown(faInfoCircle,    "Tipo de Dispositivo: No Disponible", "", "dispositivo-info") }
                                                    { this.DropDown(faInfoCircle,    "IP: No Disponible", "", "ip-info") }
                                                    { this.DropDown(faInfoCircle,    "Información Completa:", "", "completo-info") }
                                                    { this.DropDown(faInfoCircle,    "Acerca de SoftPlace Software", "/sobre-sp") }
                                                </div>
                                            { this.SubMenu(faStore,                  "Ir a la Tienda",               false, false, "https://" + (spg.VerDato("bdNombreBDPagina") ? spg.VerDato("bdNombreBDPagina").replace("/assets", "") : ""), "", "mb-5 pb-5 clear") }
                                            { this.SubMenu(faUnlock,                 "Salir",                        true, false, "") }
                                        </ul>
                                    </nav>
                                </div>
                            )
                    }
                    else 
                    {
                        return(
                            <div id="menu" onMouseEnter={ () => this.AbrirDropDownGral() } 
                                        onMouseLeave={ () => this.CerrarDropDown() }>
                                <nav>
                                    <ul style={{ paddingBottom: "50px" }}>
                                        <li className="menu-spg superior fondo-sp" id="superior">
                                            <div><FontAwesomeIcon icon={ faBars } size="lg" /></div>
                                        </li>

                                        { this.Logo() }
                                        { this.SubMenu(faHome,                          "Inicio",   false, false, "/") }
                                        { this.SubMenu(faSearch,                        "Buscar",   false, true, "ddw-search") }
                                            <div className="dropdown-menu scroll-v h-50" id="ddw-search">
                                                { this.DropDown(faPencilAlt,            "Buscar texto", "") }
                                                
                                                <input placeholder='¿Que estás buscando?' 
                                                    autoComplete="off" 
                                                    id="buscar" 
                                                    className='px-1 mx-2 border-none border-b-dot mb-3' 
                                                    onKeyUp={ (e) => this.Buscar(e) } />
                                                
                                                { oBusqueda }
                                            </div>
                                        { this.SubMenu(faDollarSign,             "Caja",             false, true, "ddw-valores") }
                                            <div className="dropdown-menu" id="ddw-valores">
                                                { this.DropDown(faCashRegister,  "Registrar comprobantes de ventas", "/facturacion?clave=" + comp.GetClave()) }
                                                { this.DropDown(faHdd,           "Facturas electrónicas pendientes", "/fae-pendiente") }
                                                { this.DropDown(null,            "-", "") }
                                                { this.DropDown(faChartPie,      "Consulta de Comprobantes", "/consulta-comprobantes") }
                                                { this.DropDown(null,            "-", "") }
                                                { this.DropDown(faQrcode,        "Cobrar Ahora", "/cobrar") }
                                            </div>
                                        { this.SubMenu(faWrench,                 "Configuraciones",  false, true, "ddw-configuraciones") }
                                            <div className="dropdown-menu" id="ddw-configuraciones">
                                                { this.SubMenu(faTag,                 "Productos",  false, true, "ddw-pr-configuraciones-prod", false, "pl-0") }
                                                    <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-prod">
                                                        { this.DropDown(faTag,        "Productos", "/listado/productos", "fondo-sp") }
                                                        { this.DropDown(null,            "-", "") }
                                                        { this.DropDown(faTags,        "Categorías", "/listado/tipos-productos", "fondo-sp") }
                                                        { this.DropDown(faTags,        "Sub-Categorías", "/listado/sub-tipos-productos", "fondo-sp") }
                                                        { this.DropDown(faDolly,        "" + cTituloMaterial, "/listado/materias-primas", "fondo-sp") }
                                                        { this.DropDown(null,            "-", "") }
                                                        { this.DropDown(faImages,       "Banners de Categorías de la Tienda", "/banners") }
                                                        { this.DropDown(faImages,       "Optimizá tus imágenes para la web", "/achicar-imagenes") }
                                                        { this.DropDown(null,            "-", "") }
                                                        { this.DropDown(faDollarSign,   "Actualización masiva de precios", "/actualizar-precios", "fondo-sp") }
                                                        { this.DropDown(faDollarSign,   "Cambiar moneda en productos", "/cambiar-moneda", "fondo-sp") }
                                                    </div>
                                                { this.SubMenu(faUsers,                 "Entidades",  false, true, "ddw-pr-configuraciones-ent", false, "pl-0") }
                                                    <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-ent">
                                                        { this.DropDown(faUserTie,    (conf.list.TituloCliente ? conf.list.TituloCliente : "Clientes"), "/listado/clientes") }
                                                        { this.DropDown(null,              "-", "") }
                                                        { this.DropDown(faUserTie,    "Tipos de " + (conf.list.TituloCliente ? conf.list.TituloCliente : "Clientes"), "/listado/tipos-entidades/clientes") }
                                                    </div>
                                                { this.SubMenu(faShippingFast,      "Envíos",  false, true, "ddw-pr-configuraciones-env", false, "pl-0") }
                                                    <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-env">
                                                        { this.DropDown(faShippingFast,    "Transportes", "/listado/transportes") }
                                                        { this.DropDown(faMapMarkedAlt,    "Zonas", "/listado/zonas-envios") } 
                                                        { this.DropDown(null,            "-", "") }
                                                        { this.DropDown(faGlobeAmericas,    "Configurar Envíos", "/zonas-envios") }
                                                    </div>
                                                { this.DropDown(faCreditCard,        "Monedas", "/listado/monedas") }
                                                { this.DropDown(null,            "-", "") }
                                                { this.SubMenu(faPercentage,                 "Cobros, Descuentos y Perfiles",  false, true, "ddw-pr-configuraciones-conf", false, "pl-0") }
                                                    <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-conf">
                                                        { this.DropDown(faDollarSign,    "Unipago", "/config-pagos") }
                                                        { this.DropDown(faPercentage,    "Descuentos", "/config-descuentos") }
                                                    </div> 
                                                { this.SubMenu(faCogs,                 "Otras Configuraciones",  false, true, "ddw-pr-configuraciones-otr", false, "pl-0 border-none") }
                                                    <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-otr" style={{ marginTop: "-100px" }}>
                                                        { this.DropDown(faStore,         "Perfiles de compra en la Tienda", "/config-perfiles-compra") }
                                                        { this.DropDown(faMobileAlt,      "Configurar WhatsApp Messaging", "/config-whatsapp-msj") }
                                                        { this.DropDown(faBuilding,      "Configurar datos de mi Negocio", "/mis-datos") }
                                                        { this.DropDown(null,            "-", "") }
                                                        { this.DropDown(faCogs,          "Panel de Control", "/panel-control") }
                                                    </div>
                                                { this.DropDown(faBroom,        "Limpiar caché de Datos", "/limpiar-cache") }
                                            </div>
                                        { this.SubMenu(faBarcode,                "Stock",                        false, true, "ddw-stock") }
                                            <div className="dropdown-menu" id="ddw-stock" style={{ marginTop: "-100px" }}>
                                                { this.DropDown(faBarcode,       "Registrar remito interno de Ajuste de Stock/Compra", "/remito-interno?clave=" + comp.GetClave()) }
                                                { this.DropDown(null,            "-", "") }
                                                { this.DropDown(faTag,           "Productos", "/listado/productos") }
                                                { this.DropDown(faBarcode,       "Consulta de Stock", "/consulta-stock") }
                                                { this.DropDown(faBarcode,       "Consulta de Rápida de Productos", "/consulta-rapida-productos") }
                                                { this.DropDown(null,                "-", "") }
                                                { this.DropDown(faTag,                "Imprimir Etiquetas", "/imprimir-etiquetas") }
                                            </div>
                                        { this.SubMenu(faChartPie,               "Consultas",                    false, true, "ddw-consultas") }
                                            <div className="dropdown-menu" id="ddw-consultas">
                                                { this.DropDown(faBarcode,      "Consulta de Stock", "/consulta-stock") }
                                                { this.DropDown(faBarcode,      "Consulta de Rápida de Productos", "/consulta-rapida-productos") }
                                                { this.DropDown(null,            "-", "") }
                                                { this.DropDown(faCopy,          "Consulta de Comprobantes", "/consulta-comprobantes") }
                                                { this.DropDown(null,            "-", "") }
                                                { this.DropDown(faUserTie,      "Consulta de " + (conf.list.TituloCliente ? conf.list.TituloCliente : "Clientes"), "/consulta-entidades") }
                                                { this.DropDown(null,            "-", "") }
                                                { this.DropDown(faChartPie,      "Consulta de Ventas WEB Pendientes", "/consulta-compras-web-pendientes") }
                                                { this.DropDown(null,            "-", "") }
                                                { this.DropDown(faChartArea,      "Consulta de Navegación de la Tienda", "/consulta-stats-tienda") }
                                                { this.DropDown(faChartArea,      "Consulta de Servicios Contratados", "/mis-servicios") }
                                            </div> 
                                        { this.SubMenu(faLock,                   "Seguridad",                    false, true, "ddw-seguridad") }
                                            <div className="dropdown-menu" id="ddw-seguridad" style={{ bottom: "30px" }}>
                                                { this.DropDown(faLock,          <button className="text-white" onClick={ () => this.Salir() }>Cerrar Sesión - Cambiar de Usuario</button>, "") }
                                                { this.DropDown(null,            "-", "") }
                                                { this.DropDown(faAsterisk,      "Cambiar Contraseña", "/cambiar-password") }
                                                { this.DropDown(null,            "-", "") }
                                                { this.DropDown(faUserShield,    "Administrar mi Perfil", "/admin-usuario") } 
                                                { spg.VerDato("usuAdmin") ? this.DropDown(faUserShield,          "Usuarios", "/listado/usuarios") : [] }
                                                { spg.VerDato("usuAdmin") ? this.DropDown(faUserShield,          "Permisos", "/listado/permisos") : [] }
                                                { spg.VerDato("usuAdmin") ? this.DropDown(null,            "-", "") : [] }
                                                { this.DropDown(faShieldAlt,          "Certificados", "/listado/certificados") }
                                                { this.DropDown(faMobileAlt,          "Equipos Conectados", "/mis-equipos") }
                                            </div>
                                        { this.SubMenu(faExclamationTriangle,    "Pendientes",                   false, true, "ddw-pendientes") }
                                            <div className="dropdown-menu" id="ddw-pendientes" style={{ marginTop: "-50px" }}>
                                                { this.DropDown(faExclamationTriangle,    "Ventas WEB pendientes", "/consulta-comprobantes?web=true&sin-relacion=true") }
                                                { this.DropDown(faExclamationTriangle,    "Productos en alerta de stock", "/consulta-stock?bajostock=true") }
                                                { this.DropDown(faExclamationTriangle,    "Ventas con pedido de devolución", "/consulta-comprobantes?web=true&sin-relacion=true&anulados=true&devueltos=true") }
                                            </div>
                                        { this.SubMenu(faInfoCircle,             "Info",                         false, true, "ddw-info") }
                                            <div className="dropdown-menu" id="ddw-info" style={{ marginTop: "-220px" }}>
                                                { this.DropDown(faInfoCircle,    "Usuario: " + spg.VerDato("usuNombre"), "/usuarios") }
                                                { this.DropDown(null,            "-", "") }
                                                { this.DropDown(faInfoCircle,    "Sucursal: " + spg.VerDato("sucDescrip"), "") }
                                                { this.DropDown(faInfoCircle,    "Razón Social: " + spg.VerDato("sucRazonSocial"), "") }
                                                { this.DropDown(faInfoCircle,    "Punto de Venta: " + spg.VerDato("pvDescrip"), "") }
                                                { this.DropDown(faInfoCircle,    "Contribuyente: " + spg.VerDato("tyDescrip"), "") }
                                                { this.DropDown(faInfoCircle,    "CUIT Empresa: " + spg.VerDato("sucCuit"), "") }
                                                { this.DropDown(faInfoCircle,   <div className="d-block col-md-12"> 
                                                                                    <img src={ spg.Server() + "clientes-softplace/images/" + spg.VerDato("Cliente-SP") + ".jpg" } 
                                                                                        alt={ spg.VerDato("sucLogo") }
                                                                                        className='fondo-blanco'
                                                                                        onError={ (e) => e.target.src = process.env.PUBLIC_URL + "/images/isologotipo-joyerias.svg" }
                                                                                        height="35" /> Logo de la Empresa
                                                                                </div>, "") }
                                                { this.DropDown(null,            "-", "") }
                                                { this.DropDown(faInfoCircle,    "Pantalla: " + window.outerWidth + " x " + window.outerHeight, "") }
                                                { this.DropDown(faInfoCircle,    "Sistema Operativo: No Disponible", "", "so-info") }
                                                { this.DropDown(faInfoCircle,    "Navegador: No Disponible", "", "browser-info") }
                                                { this.DropDown(faInfoCircle,    "Tipo de Dispositivo: No Disponible", "", "dispositivo-info") }
                                                { this.DropDown(faInfoCircle,    "IP: No Disponible", "", "ip-info") }
                                                { this.DropDown(faInfoCircle,    "Información Completa:", "", "completo-info") }
                                                { this.DropDown(faInfoCircle,    "Acerca de SoftPlace Software", "/sobre-sp") }
                                            </div>
                                        { this.SubMenu(faStore,                  "Ir a la Tienda",               false, false, "https://" + (spg.VerDato("bdNombreBDPagina") ? spg.VerDato("bdNombreBDPagina").replace("/assets", "") : ""), "", "mb-5 pb-5 clear") }
                                        { this.SubMenu(faUnlock,                 "Salir",                        true, false, "") }
                                    </ul>
                                </nav>
                            </div>
                        )
                    }
                }
                else // menú completo, licencia completa
                {
                    return(
                        <div id="menu" onMouseEnter={ () => this.AbrirDropDownGral() } 
                                    onMouseLeave={ () => this.CerrarDropDown() }>
                            <nav>
                                <ul style={{ paddingBottom: "50px" }}>
                                    <li className="menu-spg superior fondo-sp" id="superior">
                                        <div><FontAwesomeIcon icon={ faBars } size="lg" /></div>
                                    </li>

                                    { this.Logo() }
                                    { this.SubMenu(faHome,                          "Inicio",   false, false, "/") }
                                    { this.SubMenu(faSearch,                        "Buscar",   false, true, "ddw-search") }
                                        <div className="dropdown-menu scroll-v h-50" id="ddw-search">
                                            { this.DropDown(faPencilAlt,            "Buscar texto", "") }
                                            
                                            <input placeholder='¿Que estás buscando?' 
                                                   autoComplete="off" 
                                                   id="buscar" 
                                                   className='px-1 mx-2 border-none border-b-dot mb-3' 
                                                   onKeyUp={ (e) => this.Buscar(e) } />
                                            
                                            { oBusqueda }
                                        </div>
                                    { this.SubMenu(faDollarSign,                    "Caja",                         false, true, "ddw-valores") }
                                        <div className="dropdown-menu" id="ddw-valores" style={{ marginTop: "-135px" }}>
                                            { this.DropDown(faCashRegister,         "Registrar comprobantes de ventas", "/facturacion?clave=" + comp.GetClave()) }
                                            { this.DropDown(faLayerGroup,           "Facturación Masiva", "/facturacion-periodica") }
                                            { this.DropDown(faHdd,                  "Facturas electrónicas pendientes", "/fae-pendiente") }
                                            { this.DropDown(null,                   "-", "") }
                                            { this.DropDown(faFileInvoiceDollar,    "Registrar Pagos de Clientes", "/recibos?clave=" + comp.GetClave()) }
                                            { this.DropDown(faFileInvoiceDollar,    "Registrar Órdenes de Pago (Proveedores)", "/ordenes-pago?clave=" + comp.GetClave()) }
                                            { this.DropDown(faBarcode,              "Registrar factura de compra", "/compras?clave=" + comp.GetClave()) }
                                            { this.DropDown(null,                   "-", "") }
                                            { this.DropDown(faFileInvoice,          "Registrar Gastos, Egresos de caja, Retiros", "/gastos?clave=" + comp.GetClave()) }
                                            { this.DropDown(faFileInvoice,          "Registrar Ingresos de caja", "/ingreso-caja?clave=" + comp.GetClave()) }
                                            { this.DropDown(faDollarSign,           "Conversión y Traspaso de Divisas", "/convertir-divisas") }
                                            { this.DropDown(null,                   "-", "") }
                                            { this.DropDown(faCashRegister,         "Cierre de Caja", "/cierre-caja") }
                                            { this.DropDown(null,                   "-", "") }
                                            { this.DropDown(faQrcode,               "Cobrar Ahora", "/cobrar") }
                                        </div>
                                    { this.SubMenu(faWrench,                 "Configuraciones",  false, true, "ddw-configuraciones") }
                                        <div className="dropdown-menu" id="ddw-configuraciones" style={{ marginTop: "-100px" }}>
                                            { this.SubMenu(faTags,                "Productos",  false, true, "ddw-pr-configuraciones-prod", false, "pl-0 py-1") }
                                                <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-prod" style={{ marginTop: "-100px" }}>
                                                    { this.DropDown(faTag,          "Productos", "/listado/productos") }
                                                    { this.DropDown(faFileImport,   "Importar Productos desde archivo CSV", "/importar-productos") }
                                                    { this.DropDown(null,           "-", "") }
                                                    { this.DropDown(faTags,         "Categorías", "/listado/tipos-productos", "fondo-sp") }
                                                    { this.DropDown(faTags,         "Sub-Categorías", "/listado/sub-tipos-productos", "fondo-sp") }
                                                    { this.DropDown(faDolly,        "" + cTituloMaterial, "/listado/materias-primas", "fondo-sp") }
                                                    { this.DropDown(null,           "-", "") }
                                                    { this.DropDown(faRuler,        "Unidades de medida", "/listado/unidades") }
                                                    { this.DropDown(faTag,          "Propiedades", "/listado/propiedades") }
                                                    { this.DropDown(faImages,       "Banners de Categorías de la Tienda", "/banners") }
                                                    { this.DropDown(faImages,       "Optimizá tus imágenes para la web", "/achicar-imagenes") }
                                                    { this.DropDown(null,           "-", "") }
                                                    { this.DropDown(faDollarSign,   "Actualización masiva de precios", "/actualizar-precios") }
                                                    { this.DropDown(faGift,         "Configurar Fidelización de Clientes", "/fidelizacion") }
                                                    { this.DropDown(faDollarSign,   "Cambiar moneda en productos", "/cambiar-moneda", "fondo-sp") }
                                                </div>
                                            { this.SubMenu(faUsers,              "Entidades",  false, true, "ddw-pr-configuraciones-ent", false, "pl-0 py-1") }
                                                <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-ent">
                                                    { this.DropDown(faUserTie,    "Clientes", "/listado/clientes") }
                                                    { this.DropDown(faUserTag,    "Proveedores", "/listado/proveedores") }
                                                    { this.DropDown(null,              "-", "") }
                                                    { this.DropDown(faUserTie,    "Tipos de Clientes", "/listado/tipos-entidades/clientes") }
                                                    { this.DropDown(faUserTag,    "Tipos de Proveedores", "/listado/tipos-entidades/proveedores") }
                                                    { this.DropDown(null,              "-", "") }
                                                    { this.DropDown(faGift,  "Configurar Fidelización de Clientes", "/fidelizacion") }
                                                </div>
                                            { this.SubMenu(faShippingFast,       "Envíos",  false, true, "ddw-pr-configuraciones-env", false, "pl-0 border-none pb-l") }
                                                <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-env">
                                                    { this.DropDown(faShippingFast,    "Transportes", "/listado/transportes") }
                                                    { this.DropDown(faMapMarkedAlt,    "Zonas", "/listado/zonas-envios") }
                                                    { this.DropDown(null,              "-", "") }
                                                    { this.DropDown(faGlobeAmericas,   "Configurar Envíos", "/zonas-envios") }
                                                    { this.DropDown(null,              "-", "") }
                                                    { this.DropDown(faShippingFast,    "Tipos de Transportes", "/listado/tipos-entidades/transportes") }
                                                </div>
                                            { this.DropDown(null,            "-", "", "py-0 my-0") }
                                            { this.SubMenu(faToolbox,      "Administrativas",  false, true, "ddw-pr-configuraciones-adm", false, "pl-0 border-none") }
                                                <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-adm" style={{ marginTop: "-160px" }}>
                                                    { this.DropDown(faCopy,          "Tipos de Comprobante", "/listado/tipos-comprobantes") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faCreditCard,    "Monedas", "/listado/monedas") }
                                                    { this.DropDown(faUniversity,    "Bancos", "/listado/bancos") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faBuilding,      "Sucursales", "/listado/sucursales") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faHdd,           "Impuestos Internos", "/listado/impuestos-internos") }
                                                    { this.DropDown(faHdd,           "Tipos de Retenciones de Pagos", "/listado/tipos-retenciones-pagos") }
                                                    { this.DropDown(faCalendarAlt,   "Tipos de Períodos de Facturación", "/listado/tipos-periodos") }
                                                    { this.DropDown(faCalendarAlt,   "Tipos de Financiaciones o Vencimientos", "/listado/tipos-financiacion") }
                                                </div>
                                            { this.SubMenu(faPercentage,                 "Cobros y Descuentos",  false, true, "ddw-pr-configuraciones-conf", false, "pl-0 border-none") }
                                                <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-conf">
                                                    { this.DropDown(faDollarSign,    "Unipago", "/config-pagos") }
                                                    { this.DropDown(faPercentage,    "Configurar Descuentos", "/config-descuentos") }
                                                    { this.DropDown(faPercentage,    "Cupones de Descuento", "/listado/cupones") }
                                                    { this.DropDown(faGift,          "Configurar Fidelización de Clientes", "/fidelizacion") }
                                                </div>
                                            { this.SubMenu(faCogs,                 "Otras Configuraciones",  false, true, "ddw-pr-configuraciones-otr", false, "pl-0 border-none") }
                                                <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-pr-configuraciones-otr" style={{ marginTop: "-100px" }}>
                                                    { this.DropDown(faStore,         "Perfiles de compra en la Tienda", "/config-perfiles-compra") }
                                                    { this.DropDown(faMobileAlt,      "Configurar WhatsApp Messaging", "/config-whatsapp-msj") }
                                                    { this.DropDown(faBuilding,      "Configurar datos de mi Negocio", "/mis-datos") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faCogs,          "Panel de Control", "/panel-control") }
                                                </div>
                                            { this.DropDown(null,            "-", "") }
                                            { this.DropDown(faBroom,        "Limpiar caché de Datos", "/limpiar-cache") }
                                        </div>
                                    { this.SubMenu(faBarcode,                "Stock",                        false, true, "ddw-stock") }
                                        <div className="dropdown-menu" id="ddw-stock" style={{ marginTop: "-200px" }}>
                                            { this.DropDown(faBarcode,           "Registrar factura de compra", "/compras?clave=" + comp.GetClave()) }
                                            { this.DropDown(faBarcode,           "Registrar remito de venta", "/remitos?clave=" + comp.GetClave()) }
                                            { this.DropDown(faBarcode,           "Registrar remito de venta rápida", "/remito-rapido") }
                                            { this.DropDown(faBarcode,           "Registrar remito interno de Ajuste de Stock", "/remito-interno?clave=" + comp.GetClave()) }
                                            { this.DropDown(faBarcode,           "Registrar remito interdepósito", "/remito-interno/interdeposito") }
                                            { this.DropDown(null,                "-", "") }
                                            { this.DropDown(faBarcode,           "Realizar Inventario", "/inventario?clave=" + comp.GetClave()) }
                                            { this.DropDown(null,                "-", "") }
                                            { this.DropDown(faTag,                "Productos", "/listado/productos") }
                                            { this.DropDown(faBarcode,           "Consulta de Stock", "/consulta-stock") }
                                            { this.DropDown(faBarcode,           "Consulta de Rápida de Productos", "/consulta-rapida-productos") }
                                            { this.DropDown(null,                "-", "") }
                                            { this.DropDown(faTag,                "Imprimir Etiquetas", "/imprimir-etiquetas") }
                                        </div>
                                    { this.SubMenu(faChartPie,               "Consultas",                    false, true, "ddw-consultas") }
                                        <div className="dropdown-menu" id="ddw-consultas">
                                            { this.SubMenu(faTags,       "Stock",  false, true, "ddw-consultas-stock", false, " pl-0 py-1") }
                                                <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-consultas-stock">
                                                    { this.DropDown(faBarcode,      "Consulta de Stock", "/consulta-stock") }
                                                    { this.DropDown(faBarcode,      "Consulta de Rápida de Productos", "/consulta-rapida-productos") }
                                                </div> 
                                            { this.SubMenu(faIdCard,       "Cuentas Corrientes",  false, true, "ddw-consultas-ctacte", false, " pl-0 py-1") }
                                                <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-consultas-ctacte">
                                                    { this.DropDown(faIdCard,       "Consulta de Cuentas Corrientes de Clientes", "/consulta-ctacte-clientes") }
                                                    { this.DropDown(faChartPie,     "Consulta de Deudas de Clientes", "/consulta-deudas/clientes") }
                                                    { this.DropDown(faUserTie,      "Consulta de Clientes", "/consulta-entidades") }
                                                    { conf.list.ClientesConEscalafon ? this.DropDown(faUserTie,      "Consulta de Árbol de Escalafones", "/consulta-arbol-escalafones") : [] }
                                                    { this.DropDown(faGift,         "Consulta de Puntos por Fidelización", "/consulta-ctacte-promociones") }
                                                    { this.DropDown(null,           "-", "") }
                                                    { this.DropDown(faIdCard,       "Consulta de Cuentas Corrientes de Proveedores", "/consulta-ctacte-proveedores") }
                                                    { this.DropDown(faChartPie,     "Consulta de Deudas a Proveedores", "/consulta-deudas/proveedores") }
                                                </div> 
                                            { this.SubMenu(faFileInvoiceDollar,       "Financieras",  false, true, "ddw-consultas-fin", false, " pl-0 py-1") }
                                                <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-consultas-fin" style={{ marginTop: "-100px" }}>
                                                    { this.DropDown(faChartPie,      "Consulta de Ventas", "/consulta-ventas") }
                                                    { this.DropDown(faChartPie,      "Consulta de Ventas WEB Pendientes", "/consulta-compras-web-pendientes") }
                                                    { this.DropDown(faChartPie,      "Consulta de Entradas y Salidas", "/consulta-entradas-salidas") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faMoneyCheckAlt, "Consulta de Cheques de Terceros", "/consulta-cheques") }
                                                    { this.DropDown(null,            "-", "") }
                                                    { this.DropDown(faChartPie,      "Consulta de Cierres de caja", "/consulta-cierres-caja") }
                                                    { this.DropDown(faChartPie,      "Consulta de Tipos de Comprobantes", "/listado/tipos-comprobantes/0") }
                                                </div> 
                                            { this.SubMenu(faCopy,       "Comprobantes",  false, true, "ddw-consultas-comp", false, " pl-0 py-1") }
                                                <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-consultas-comp">
                                                    { this.DropDown(faChartPie,      "Consulta de Comprobantes", "/consulta-comprobantes") }
                                                    { this.DropDown(faHdd,      "Consulta de Pendientes a Facturar Electrónicamente", "/fae-pendiente") }
                                                </div>  
                                            { this.SubMenu(faChartArea,       "Otros",  false, true, "ddw-consultas-otr", false, " pl-0 py-1") }
                                                <div className="dropdown-menu dropdown-menu-h no-hover dropdown-sub-menu" id="ddw-consultas-otr">
                                                    { this.DropDown(faChartArea,      "Consulta de Navegación de la Tienda", "/consulta-stats-tienda") }
                                                    { this.DropDown(faChartArea,      "Consulta de Servicios Contratados", "/mis-servicios") }
                                                </div> 
                                        </div> 
                                    { this.SubMenu(faLock,                   "Seguridad",                    false, true, "ddw-seguridad") }
                                        <div className="dropdown-menu" id="ddw-seguridad" style={{ marginTop: "-160px" }}>
                                            { this.DropDown(faLock,          <button className="text-white" onClick={ () => this.Salir() }>Cerrar Sesión - Cambiar de Usuario</button>, "") }
                                            { this.DropDown(null,            "-", "") }
                                            { this.DropDown(faAsterisk,          "Cambiar Contraseña", "/cambiar-password") }
                                            { this.DropDown(null,            "-", "") }
                                            { this.DropDown(faUserShield,    "Administrar mi Perfil", "/admin-usuario") } 
                                            { this.DropDown(faUserShield,    "Usuarios", "/listado/usuarios") }
                                            { this.DropDown(faUserShield,    "Permisos", "/listado/permisos") }
                                            { this.DropDown(faUserShield,    "Tipos de Usuario", "/listado/tipos-usuarios") }
                                            { this.DropDown(null,            "-", "") }
                                            { this.DropDown(faShieldAlt,     "Certificados", "/listado/certificados") }
                                            { this.DropDown(faMobileAlt,     "Equipos Conectados", "/mis-equipos") }
                                        </div>
                                    { this.SubMenu(faExclamationTriangle,    "Pendientes",                   false, true, "ddw-pendientes") }
                                        <div className="dropdown-menu" id="ddw-pendientes" style={{ marginTop: "-150px" }}>
                                            { this.DropDown(faHdd,                    "Consulta de Pendientes a Facturar Electrónicamente", "/fae-pendiente") }
                                            { this.DropDown(null,                     "-", "") }
                                            { this.DropDown(faExclamationTriangle,    "Ventas WEB pendientes", "/consulta-comprobantes?web=true&sin-relacion=true") }
                                            { this.DropDown(faExclamationTriangle,    "Productos en alerta de stock", "/consulta-stock?bajostock=true") }
                                            { this.DropDown(faExclamationTriangle,    "Cuotas que deberías cobrar hoy", "/consulta-deudas?vencimiento=hoy") }
                                            { this.DropDown(faExclamationTriangle,    "Ventas con pedido de devolución", "/consulta-comprobantes?web=true&sin-relacion=true&anulados=true&devueltos=true") }
                                        </div>
                                    { this.SubMenu(faInfoCircle,             "Info",                         false, true, "ddw-info") }
                                        <div className="dropdown-menu" id="ddw-info" style={{ marginTop: "-360px" }}>
                                            { this.DropDown(faInfoCircle,    "Usuario: " + spg.VerDato("usuNombre"), "/usuarios") }
                                            { this.DropDown(null,            "-", "") }
                                            { this.DropDown(faInfoCircle,    "Sucursal: " + spg.VerDato("sucDescrip"), "") }
                                            { this.DropDown(faInfoCircle,    "Razón Social: " + spg.VerDato("sucRazonSocial"), "") }
                                            { this.DropDown(faInfoCircle,    "Punto de Venta: " + spg.VerDato("pvDescrip"), "") }
                                            { this.DropDown(faInfoCircle,    "Contribuyente: " + spg.VerDato("tyDescrip"), "") }
                                            { this.DropDown(faInfoCircle,    "CUIT: " + spg.VerDato("sucCuit"), "") }                                        
                                            { this.DropDown(faInfoCircle,   <div className="d-block col-md-12">   
                                                                                <img src={ spg.Server() + "clientes-softplace/images/" + spg.VerDato("Cliente-SP") + ".jpg" } 
                                                                                    alt={ spg.VerDato("sucLogo") }
                                                                                    className='fondo-blanco'
                                                                                    onError={ (e) => e.target.src = process.env.PUBLIC_URL + "/images/isologotipo-joyerias.svg" }
                                                                                    height="35" /> Logo de la Empresa
                                                                            </div>, "") }
                                            { this.DropDown(null,            "-", "") }
                                            { this.DropDown(faInfoCircle,    "Pantalla: " + window.outerWidth + " x " + window.outerHeight, "") }
                                            { this.DropDown(faInfoCircle,    "Sistema Operativo: No Disponible", "", "so-info") }
                                            { this.DropDown(faInfoCircle,    "Navegador: No Disponible", "", "browser-info") }
                                            { this.DropDown(faInfoCircle,    "Tipo de Dispositivo: No Disponible", "", "dispositivo-info") }
                                            { this.DropDown(faInfoCircle,    "IP: No Disponible", "", "ip-info") }
                                            { this.DropDown(faInfoCircle,    "Información Completa: ", "", "completo-info") }
                                            { this.DropDown(faInfoCircle,    "Acerca de SoftPlace Software", "/sobre-sp") }
                                        </div>
                                    { this.SubMenu(faStore,                  "Ir a la Tienda",               false, false, "https://" + (spg.VerDato("bdNombreBDPagina") ? spg.VerDato("bdNombreBDPagina").replace("/assets", "") : ""), false, "") }
                                    { this.SubMenu(faUnlock,                 "Salir",                        true, false, "") }
                                </ul>
                            </nav>
                        </div>
                    );
                }
            }
            else
            {
                return(
                        <div id="menu" onMouseEnter={ () => this.AbrirDropDownGral() } 
                                    onMouseLeave={ () => this.CerrarDropDown() }>
                            <nav>
                                <ul>
                                    { this.Logo() }
                                    { this.SubMenu(faHome, "Inicio", false, false, "/inicio") }
                                    { this.SubMenu(faKey, "Ingresar a POSSUM", false, false, "/login") }
                                    { this.SubMenu(faInfoCircle, "¿Que es POSSUM?", false, false, "/inicio#ofrecemos") }
                                    { this.SubMenu(faCreditCard, "Conocé Unipago", false, false, "/pagar") }
                                    { this.SubMenu(faQrcode, "Creá tu QR Gratis", false, false, "/crear-qr") }
                                    { this.SubMenu(faImages, "Optimizá tus imágenes para la web", false, false, "/achicar-imagenes") }
                                </ul>
                            </nav>
                        </div>
                      )
            }
        }
        else
        {
            return(
                <div id="menu" onMouseEnter={ () => this.AbrirDropDownGral() } 
                            onMouseLeave={ () => this.CerrarDropDown() }>
                    <nav>
                        { !this.props.possum 
                            ?   <ul>
                                    { this.Logo() }
                                    { this.SubMenu(faHome, "Panel de Control", false, false, spg.VerDato("usuario-pagar") ? "/pagar/principal" : "/pagar") }
                                    { this.SubMenu(faQrcode, "COBRAR AHORA", false, false, "/pagar/config/qr") }
                                    { this.SubMenu(faWrench, "Configurar", false, true, "ddw-configpagar") }
                                        <div className="dropdown-menu" id="ddw-configpagar">
                                            { this.DropDown(faWrench,    "Configurar Cuentas Bancarias", "/pagar/config/cuentas-bancarias") }
                                            { this.DropDown(faWrench,    "Configurar Ualá Bis", "/pagar/config/uala") }
                                            { this.DropDown(faWrench,    "Configurar PayPal", "/pagar/config/paypal") }
                                            { this.DropDown(faWrench,    "Configurar MercadoPago", "/pagar/config/mercado-pago") }
                                            { this.DropDown(faWrench,    "Configurar Cobros en Efectivo", "/pagar/config/pago-efectivo") }
                                                    { this.DropDown(null,            "-", "") }
                                            { this.DropDown(faWrench,    "Configurar Datos del comercio", "/pagar/config/datos") }
                                        </div>
                                    { this.SubMenu(faShieldAlt, "Seguridad", false, false, "/pagar/config/seguridad") }
                                    { this.SubMenu(faUserAlt, "Developers", false, false, "/pagar/config/help") }
                                    { this.SubMenu(faUnlock, "Salir", true, false, "") }
                                </ul>
                            :   <ul>
                                    { this.Logo() }
                                    { this.SubMenu(faQuestionCircle, "¿Que es Unipago?", false, false, "unipago.app") }
                                </ul>
                        }
                    </nav>
                </div>
            )
        }
    }

    DropDown(oIcon, cTexto, cModulo, cClase = "")
    { 
        if (cTexto !== "-")
        { 
            return (
                <div className={ "d-block " + cClase} key={ "mnu-" + Math.random() }>
                    { cModulo
                        ?   <a href={ (cModulo) } className='item-mnu-sp' datatext={ cTexto }>
                                <div>
                                    <FontAwesomeIcon icon={ (oIcon) } />
                                </div>
                                
                                <span className={ cClase ? "txt-" + cClase : "" }>
                                    { cTexto }
                                </span>
                            </a>
                        :   <span>
                                <div><FontAwesomeIcon icon={ (oIcon) } /></div>
                                
                                <span className={ cClase ? "txt-" + cClase : "" }>
                                    { cTexto }
                                </span>
                            </span>
                    }
                </div>
            );
        }
        else
        {
            return (
                <div className="linea-separadora"> </div>
            );
        }
    }

    SubMenu(icon, cTexto, inferior, Padre, Modulo, CerrarPadre = true, cClaseAgregar = "", cClaseAgregarIcono = "")
    {
        if (inferior)
        {
            if (!this.state.MostrarTexto)
            {
                // Sólo muestra el menu hamburguesa en celular, el escritorio se ve igual
                return(
                    <li id="inferior-cerrar" className="menu-spg inferior fondo-sp" onClick={ () => this.AbrirDropDownGral() }>
                        <div>
                            <FontAwesomeIcon className={ cClaseAgregarIcono } icon={ faBars } size="lg" />
                        </div>
                        
                        { this.TextoMenu(cTexto, Padre) } 
                    </li>
                );
            }
            else 
            {
                // si el texto se muestra, en celular se muestar todo
                if (this.state.MostrarTexto)
                {
                    return(
                        <li id="inferior-cerrar" className="menu-spg inferior" onClick={ () => this.Salir() }>
                            <div><FontAwesomeIcon className={ cClaseAgregarIcono } icon={ (icon) }/></div>
                            
                            { this.TextoMenu(cTexto, Padre) } 
                        </li>
                    );
                }
                else
                {
                    // Si no se muestra el texto, se oculta este botón 
                    return(
                        <li id="inferior-cerrar" className="menu-spg inferior" onClick={ () => this.Salir() }>
                            <div><FontAwesomeIcon className={ cClaseAgregarIcono } icon={ (icon) }/></div>
                            
                            { this.TextoMenu(cTexto, Padre) } 
                        </li>
                    );
                }
            }
        }
        else
        {
            if (Padre)
            {
                return(
                    <li className={ cClaseAgregar } onClick={ () => this.AbrirDropDown(Modulo, CerrarPadre) }>
                        <div className='col-lg-12 p-0' onClick={ () => {
                                                                            this.AbrirDropDown(Modulo, CerrarPadre)

                                                                            if(cTexto === "Buscar")
                                                                            {
                                                                                setTimeout(() => {
                                                                                                    if (document.getElementById("buscar"))
                                                                                                    {
                                                                                                        document.getElementById("buscar").focus() 
                                                                                                    }
                                                                                                }, 200) 
                                                                            }
                                                                        }}>
                            <div>
                                <FontAwesomeIcon className={ cClaseAgregarIcono } icon={ (icon) } />
                            </div>
                            
                            {this.TextoMenu(cTexto, Padre) } 
                        </div>
                    </li>
                );
            }
            else
            {
                if (Modulo.substr(0, 4) !== "http")
                {
                    return(
                        <li className={ cClaseAgregar } onClick={ () => window.location = Modulo }>
                            <a href={ (Modulo) }>
                                <div><FontAwesomeIcon className={ cClaseAgregarIcono } icon={ (icon) } /></div>
                                
                                {this.TextoMenu(cTexto, Padre) } 
                            </a>
                        </li>
                    );
                }
                else 
                {
                    return(
                        <li className={ cClaseAgregar } onClick={ () => window.location = Modulo }>
                            <a href={ (Modulo) } target='_blank' without="true" rel="noopener noreferrer">
                                <div>
                                    <FontAwesomeIcon className={ cClaseAgregarIcono } icon={ (icon) } />
                                </div>
                                
                                {this.TextoMenu(cTexto, Padre) } 
                            </a>
                        </li>
                    );
                }
            }
        }
    }

    AbrirDropDownGral()
    {
        this.setState({ MostrarTexto: !this.state.MostrarTexto }); 

        document.getElementById("menu").classList.add("slide-in-left")
        document.getElementsByTagName("nav")[0].classList.add("h-100-vh")

        setTimeout(() => 
        {
            // sólamente estira el salir cuando corresponde
            if (document.getElementById("inferior-cerrar") && document.getElementsByClassName("slide-in-left") && this.state.MostrarTexto)
            {
                document.getElementById("inferior-cerrar").classList.add("inferior-abierto")
            }
        }, 100);
    }

    AbrirDropDown(cNombre, CerrarPadre = true)
    {
        if (cNombre === "ddw-info")
        {
            this.BuscarInfoNavegador()
        }

        const aDropDowns = document.getElementsByClassName("dropdown-menu");
        const aDropDownsH = document.getElementsByClassName("dropdown-menu-h");

        document.getElementsByTagName("nav")[0].style.overflowY = "inherit"

        if (CerrarPadre)
        {
            for (var i = 0; i < aDropDowns.length; i++)
            {
                aDropDowns[i].style.display = "none"; 
            }
        }

        for (var j = 0; j < aDropDownsH.length; j++)
        {
            aDropDownsH[j].style.display = "none"; 
        }

        if (document.getElementById(cNombre))
        {
            document.getElementById(cNombre).style.display = "inline-block";
            
            if ((document.getElementById(cNombre).offsetTop + document.getElementById(cNombre).clientHeight) > window.outerHeight)
            {
                document.getElementById(cNombre).style.bottom = 0;
            }
        }
        else
            swal("Estamos trabajando", "Este módulo aún no esta activo, disculpe las molestias, pronto te traeremos más noticias", "info")
    }

    CerrarDropDown(cNombre)
    {
        this.setState({ MostrarTexto: !this.state.MostrarTexto, encontrados: [] });

        const aDropDowns = document.getElementsByClassName("dropdown-menu");

        for (var i = 0; i < aDropDowns.length; i++)
        {
            aDropDowns[i].style.display = "none";
        }
        
        document.getElementsByTagName("nav")[0].classList.remove("h-100-vh")
        document.getElementsByTagName("nav")[0].classList.remove("overflow-visible")
        document.getElementsByTagName("nav")[0].style.overflowY = "scroll"
    }

    TextoMenu(texto, Padre)
    {
        if (this.state.MostrarTexto === true)
        {
            if (Padre === true)
                return(<span className='col-lg-10 pl-0 text-nowrap'>{ texto } <FontAwesomeIcon icon={ faChevronRight } /></span>);
            else 
                return(<span>{ texto }</span>);
        }
        else
        {
            return("");
        }
    }

    Logo()
    {
        const spg = new DatosSPG();
        var cImg = process.env.PUBLIC_URL + "/images/empleados.svg";
        
        if (spg.VerDato("usuFoto") !== "" && spg.VerDato("usuFoto") !== null)
        {
            cImg = spg.VerDato("usuFoto"); 
        }
        else 
        {
            cImg = spg.Server() + "clientes-softplace/images/" + spg.VerDato("Cliente-SP") + ".jpg"
        }

        const cUsuario = spg.VerDato("usuNombre")
        const cSucursal = spg.VerDato("sucDescrip") 
        const cNombrePagar = spg.VerDato("nombre-pagar")
        
        if (this.state.MostrarTexto === true)
        {
            return(
                <li className="usuario-gr">
                    <a href="/admin-usuario">
                        <img src={ cImg } alt="Usuario"
                            onError={ (e) => e.target.src = process.env.PUBLIC_URL + "/images/empleados.svg" } />
                        <h4>
                            { (cUsuario ? cUsuario : (cNombrePagar ? cNombrePagar : "Invitado")) }
                        </h4>
                        <h6>
                            { (cSucursal ? cSucursal : (cNombrePagar ? "Unipago" : "POSSUM")) }
                        </h6>
                    </a>
                </li>);
        }
        else
        {
            return(
                <li className="logo">
                    <a href="/">
                        <img alt="SoftPlace Software" 
                             src={ cImg }
                             onError={ (e) => e.target.src = process.env.PUBLIC_URL + "/images/empleados.svg" } />
                    </a>
                    
                    <div className="Nombre">
                        { (cUsuario ? cUsuario : (cNombrePagar ? cNombrePagar : "Invitado")) }
                    </div>
                    <div className="Sucursal">
                        { (cSucursal ? cSucursal : (cNombrePagar ? "Unipago" : "POSSUM")) }
                    </div>
                </li>
            );
        }
    }

    Salir()
    {
        const spg = new DatosSPG();

        if (!this.props.pagar)
        {
            spg.GuardarDato("volver", "")
            spg.CambiarUsuario()
        }
        else
        {
            spg.EliminarDato("id-registro-pagar")
            spg.EliminarDato("usuario-pagar")
            spg.EliminarDato("nombre-pagar")
            spg.EliminarDato("mail-pagar")
            spg.EliminarDato("ultimo-uso-pagar")
            spg.EliminarDato("archivocuentas-pagar")
            spg.EliminarDato("dni-pagar")
            spg.EliminarDato("titular-pagar")
            spg.EliminarDato("cp-pagar")
            spg.EliminarDato("direccion-comisionista-pagar")
            spg.EliminarDato("direccion-pagar")

            spg.EliminarDato("ua-user-pagar")
            spg.EliminarDato("ua-recargo-pagar")
            spg.EliminarDato("ua-client-secret-pagar")
            spg.EliminarDato("ua-client-id-pagar")
            
            spg.EliminarDato("mp-token-pagar")
            spg.EliminarDato("mp-client-id-pagar")
            spg.EliminarDato("mp-client-secret-pagar")
            spg.EliminarDato("mp-cuenta-pagar")
            spg.EliminarDato("mp-recargo-pagar")
            
            spg.EliminarDato("pp-client-id-pagar")
            spg.EliminarDato("pp-user-pagar")
            spg.EliminarDato("pp-client-secret-pagar")
            spg.EliminarDato("pp-recargo-pagar")
            spg.EliminarDato("pp-cotizacion-pagar")

            spg.EliminarDato("crip-Lemon-tag")
            spg.EliminarDato("crip-Belo-tag")
            spg.EliminarDato("crip-BBit-tag")
            spg.EliminarDato("crip-Ripio-tag")
            spg.EliminarDato("crip-Binance-tag")
            spg.EliminarDato("crip-Paxful-tag")
            spg.EliminarDato("crip-ETH")
            spg.EliminarDato("crip-BTC")
            spg.EliminarDato("crip-BSC")
            spg.EliminarDato("crip-Polygon")
            spg.EliminarDato("crip-LNetwork")
            spg.EliminarDato("crip-PINetwork")
            spg.EliminarDato("crip-descuento")

            window.location = "/pagar/seguridad/login";
        }
    }

    VerModulo(element)
    {
        window.location = element;
    }

    BuscarInfoNavegador()
    {
        const spg = new DatosSPG()
        const aParametros = [{
                                nombre: "dato", 
                                valor: "TODOS"
                            }]
        
        spg.LeerAPI("consultas/", "ver-datos-navegador", aParametros).then((jRta) =>
        {
            if (jRta.status === 200)
            {
                const cComilla = '"'
                
                document.getElementsByClassName("txt-so-info")[0].innerHTML = "Sistema Operativo: " + jRta["so"]
                document.getElementsByClassName("txt-ip-info")[0].innerHTML = "IP: " + jRta["ip"]
                document.getElementsByClassName("txt-dispositivo-info")[0].innerHTML = "Tipo de Dispositivo: " + jRta["dispositivo"]
                document.getElementsByClassName("txt-browser-info")[0].innerHTML = "Navegador: " + jRta["browser"]
                document.getElementsByClassName("txt-completo-info")[0].innerHTML = "<span onclick='alert(" + cComilla + jRta['browsercompleto'] + cComilla + ");'>Información Completa</span>"
            }
            else 
            {
                console.log(aParametros)
                console.log(jRta)

                document.getElementsByClassName("txt-so-info")[0].innerHTML = "Sistema Operativo: Error al consultar"
                document.getElementsByClassName("txt-ip-info")[0].innerHTML = "IP: Error al consultar"
                document.getElementsByClassName("txt-dispositivo-info")[0].innerHTML = "Tipo de Dispositivo: Error al consultar"
                document.getElementsByClassName("txt-browser-info")[0].innerHTML = "Navegador: Error al consultar"                
                document.getElementsByClassName("txt-completo-info")[0].innerHTML = "Información Completa: Error al consultar"
            }
        })
    }

    Buscar(e)
    { 
        const oItems = document.getElementsByClassName("item-mnu-sp")
        let aItemsEncontrados = []

        for (let x1 = 0; x1 < oItems.length; x1++)
        { 
            if (this.Normalizar(oItems[x1].getAttribute("datatext")) && (this.Normalizar(oItems[x1].getAttribute("datatext")).includes(this.Normalizar(e.target.value).trim())
                                                                        || this.Normalizar(oItems[x1].href).includes(this.Normalizar(e.target.value).trim())))
            {
                let bExiste = false

                for (let x3 = 0; x3 < aItemsEncontrados.length; x3++)
                {
                    if (this.Normalizar(aItemsEncontrados[x3].texto) === this.Normalizar(oItems[x1].getAttribute("datatext")))
                    {
                        bExiste = true
                        x3 = aItemsEncontrados.length
                    }
                }

                if (!bExiste)
                {
                    aItemsEncontrados.push({ texto: oItems[x1].getAttribute("datatext"), 
                                             link: oItems[x1].href })
                }
            }
        }

        this.setState({ encontrados: aItemsEncontrados })
    }

    Normalizar(cTexto)
    {
        return cTexto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
}

export default Menu;